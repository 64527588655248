@use "variables"as variable;

#returns-and-replacement-page {

    .container {
        margin-top: 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;     

        width: 96%;

        p {
            color: #141736;
        }

        a {
            color: #0017FF !important;
            margin-bottom: 0.8rem;
        }

    }

}