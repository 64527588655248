@use "variables" as variable;

.product-carousel {
    overflow-x: scroll;
    padding: 16px 0;
    padding-left: 16px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 1fr;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

#product-quick-preview-card {
    border: 0px !important;
    // margin: 3vw 2vw;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    padding: 8px 0;
    position: relative;
    width: 9.3rem;
    min-width: 9.3rem;
    margin-right: 16px !important;
    a {
        &:hover {
            color: #212529;
        }
    }

    .card-image-container {
        .discount-tag {
            top: 0;
            left: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.8rem;
        }
        .fade {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }  
        .fade-hidden {
            opacity: 0;
        }
        .card-image {
            object-fit: cover;
            width: 90%;
            display: block;
            margin: auto;
            height: 120px;
            max-height: 162px;
            border-radius: 8px;
        }

        .sale-tag {
            top: 0;
            left: 0;
            position: absolute;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            font-size: 50%;
            width: 1.8rem;
            // height: 0.9rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .product-out-of-stock-container {
        background-color: #ffffff;
        position: absolute;
        height: fit-content !important;
        top: 46%;
        width: 96%;
        // right: 1%;
        align-self: center;

        .out-of-stock-text {
            color: #de3c63;
            margin-bottom: 0px !important;
            font-size: 0.85rem;
            font-weight: 500;
        }
    }

    .product-name {
        font-size: 0.8125rem;
        font-weight: 500;
        text-align: left;
        padding: 0 3%;
        margin-top: 1rem !important;
        margin-bottom: 0.5rem !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 2.2em;
        line-height: 1.1;
        overflow: hidden;
    }

    .coin-container{
        display: inline-block;
        height: 1.5rem;
        border : solid 1px #5428A6;
        background-color: #5428A60D;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding : 5px 7px;
    }

    .fade {
        opacity: 1;
        transition: opacity 0.7s ease-in-out;
    }  
    .fade-hidden {
        opacity: 0;
    }

    .price {
        padding: 0 3%;

        .original-price {
            color: #7a7d99;
            font-size: 1rem;
            text-decoration: line-through;
            font-weight: 500;
            margin-right: 2vw;
        }

        .original-price-no-discount{
            font-weight: 800;
            font-size: 1rem;
            color: #141736;
            margin-right: 2vw;
        }

        .discounted-price {
            font-weight: 800;
            font-size: 1rem;
            color: #141736;
            margin-right: 2vw;
        }
    }

    .percentage-off {
        // margin-top: 0.1rem;
        color: #fff;
        // font-size: 0.9rem;
        font-size: 0.55rem;
        font-weight: 600;
        word-spacing: 0.5vw;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-left: 0.15rem;
        // padding: 0 3%;
        // margin-top: auto;
        // justify-self: center;
        z-index: 5;
        width: 100%;
        line-height: 1.2;
        margin-top: 4px;

        span {
            color: #fff;
            font-weight: 600;
            display: block;
            justify-content: center;
        }
    }

    .share-button {
        height: 30px;
        display: block;
        border: 1.2px solid #d2d2d2;
        border-radius: 8px;
        padding: 2px;
        width: 30px;
    }

    .share-button-loader {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        // display: inline-flex;
        background: transparent;
        /* Seperator 2 */

        border: none;
        border-radius: 6px;

        .share-loader {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            vertical-align: -0.125em;
            border: 0.15em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            -webkit-animation: 0.75s linear infinite spinner-border;
            animation: 0.75s linear infinite spinner-border;
            color: #573996 !important;

            .spinner-border {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                vertical-align: -0.125em;
                border: 0.2em solid currentColor !important;
                border-right-color: transparent;
                border-radius: 50%;
                -webkit-animation: 0.75s linear infinite spinner-border;
                animation: 0.75s linear infinite spinner-border;
            }
        }
    }
}

.see-all-card {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .see-all-btn {
        font-size: 1rem;
        background-color: #ffffff;
        color: #5427a6;
        font-weight: 600;
    }
}
