$font-family-roboto-regular: "Roboto";

$screen-xxl: 1440px;
$screen-xl: 1200px;
$screen-lg: 1024px;
$screen-lg-min: 900px;
$screen-tablet: 768px;
$screen-sm: 728px;
$screen-xs: 540px;
$screen-xs-mobile: 360px;
$screen-mobile-max: 480px;
$footer-margin-bottom: 60px;
$footer-height: 4.2rem;
$page-margin-with-footer: calc(100vh - $footer-height);
$header-height: 3.8rem;
$header-height-rest: 3.4rem;
$vendor-cart-height: 60vw;
