@use "variables" as variable;

@keyframes emojiLoader {
    0% {
        content: url("https://assets.bobblekeyboard.net/super-apps/webview-icons/taxi_icon_4x.png");
    }

    35% {
        content: url("https://assets.bobblekeyboard.net/super-apps/webview-icons/gaming_console_icon_4x.png");
    }

    65% {
        content: url("https://assets.bobblekeyboard.net/super-apps/webview-icons/shopping_bag_icon_4x.png");
    }

    90% {
        content: url("https://assets.bobblekeyboard.net/super-apps/webview-icons/bobble_logo_4x.png");
    }
}

#splash-screen-container {
    background-color: #ffffff;
    min-height: 100vh;
    .body {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;

        .header {
            display: flex;
            height: 100%;
            width: 100%;

            .splash-screen-top {
                height: 100%;
                width: 100%;
            }
        }

        .container {
            position: absolute;
            bottom: 20vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .splashScreenBobbleLogoDiv {
                display: flex;
                margin: auto;
                justify-content: center;
                .splashScreenBobbleLogoImg {
                    width: 45%;
                }
            }
        }
    }

    .footer {
        width: 100vw;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .splash-screen-left {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 30%;
        height: 100px;
        margin-left: 20px;
    }

    .splash-screen-right {
        position: absolute;
        align-items: right;
        right: 0px;
        bottom: 0px;
        width: 49%;
        height: 120px;
        margin-right: 20px;
    }
}
