@use "variables" as variable;

#categories-list-page {
    background-color: #ffffff;

    .categories-container {
        display: flex;
        width: 100vw;
        align-items: center;
        flex-flow: row wrap;

        .category {
            width: 33vw;
            // height: 160px;
            border-bottom: 1px solid #eeeff8;
            border-right: 1px solid #eeeff8;

            .category-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .category-image {
                    margin-top: 1rem;
                    display: inline-block;
                    object-fit: cover;
                    width: 84px;
                    height: 84px;
                }

                .category-name {
                    width: 80%;
                    font-weight: 600;
                    font-size: 0.6875rem;
                    color: #000000;
                    text-align: center;
                    margin-top: 8px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
