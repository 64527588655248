@use "variables" as variable;

.sub-categories-container {
  margin-top: 8px;
  padding-left: 16px;
  .sub-categories-carousel {
    display: flex;
    overflow-x: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .sub-category-item {
      margin-right: 18px;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: 13px;
        margin-top: 8px;
        text-align: center;
        font-weight: 600;
        color: #000;
      }
    }
  }
}
