@use "variables" as variable;

.category-featured-container {
  margin-top: 8px;
  padding: 24px 0;
  padding-left: 16px;
  background-color: #f5f5f5;
  .category-featured-carousel {
    display: flex;
    overflow-x: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .featured-item {
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 9.3rem;
        min-width: 9.3rem;
      }
    }
  }
}
