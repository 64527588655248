@use "variables" as variable;

#orders-page {
    background-color: #eeeff8;
    height: 100vh;

    .orders-list-container {
        #order-quick-preview-card {
            background-color: #ffffff;
            height: 7rem;
            padding: 2vw 0vw;
            width: 100vw;

            .double-image-container {
                padding: 4% 3vw !important;
            }

            .product-images-container {
                width: 30vw;
                height: 90%;
                padding: 0vw 3vw;
                display: flex;
                flex-wrap: wrap;
                // justify-content: center;
                align-items: center;

                .product-image {
                    height: auto;
                    width: 50%;
                    max-width: 50%;
                    max-height: 50%;
                    // width: 70%;
                    // height: 80%;
                }

                .single-image {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

                .double-image {
                    height: 100%;
                    width: 50%;
                    max-width: 50%;
                    object-fit: contain;
                }

                .more-than-two-images {
                    height: auto;
                    width: 50%;
                    max-width: 50%;
                    max-height: 50%;
                    object-fit: contain;
                }

                .company-logo {
                    position: relative;
                    top: -15%;
                    left: 95%;
                }

                .product-count-container {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: 40%;

                    .product-count {
                        font-size: 0.7rem;
                        color: #7a7d99;
                    }
                }
            }

            .body {
                max-width: 62vw;
                width: 62vw;
                margin-left: 0.7rem;

                .product-name {
                    color: #141736;
                    display: inline-block;
                    // vertical-align: middle;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 0.9rem;
                    width: 90%;
                    text-overflow: ellipsis;
                }

                .order-status {
                    color: #7a7d99;
                    font-size: 0.8rem;
                }
                .order-id {
                    color: #141736;
                    font-size: 0.8rem;
                }
                .text-red {
                    color: #e66d8a;
                }
            }

            .icon-container {
                width: 8vw;

                .right-arrow-icon {
                    color: #7a7d99;
                }
            }
        }
    }

    .error-message-container {
        .continue-shopping-button {
            width: 35%;
            background-color: #5427a6;
            color: #ffffff !important;
            font-size: 4vw;
            font-weight: 500;
            padding: 0.6rem 0.5rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
