#top-deals-section {
    padding: 20px 0 8px;
    background-image: url("https://www.linkpicture.com/q/top-deals-background.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 4px;

        .left-container {
            display: flex;
            align-items: flex-end;
            .text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 900;
                font-size: 30px;
                color: #ffffff;
                border: 0.681818px solid #000000;
                text-shadow: 0px 3.2px 0px #fdb400;
                @media only screen and (max-width: 405px) {
                    font-size: 24px;
                }
            }
        }

        .right-container {
            display: flex;
            justify-content: center;
            .button {
                display: flex;
                background: #ff5c00;
                border-radius: 4px;
                padding: 6px 0;
                min-width: 164px;
                text-align: center;
                align-self: center;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.05em;
                color: #ffffff;
                justify-content: center;
                @media only screen and (max-width: 405px) {
                    font-size: 12px;
                    min-width: 132px;
                }
            }
        }
    }

    .card-container {
        padding-left: 5%;
        margin-top: 2%;
    }
}

#see-all-preview-card {
    border: 0px !important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
    border-radius: 8px;
    text-align: center;
    padding: 8px 0;
    position: relative;
    min-width: 9.3rem;
    margin-right: 16px !important;
    background-color: #ffffff;
    height: 260px;

    .see-all-card-btn {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #573996;
    }
}

#top-deals-page {
    padding: 24px 0 40px;
    background-image: url("https://www.linkpicture.com/q/top-deals-background.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .top-container {
        display: flex;
        justify-content: center;

        .gif {
            padding-top: 10px;
        }

        .text {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 900;
            font-size: 38px;
            color: #ffffff;
            border: 0.681818px solid #000000;
            text-shadow: 0px 3.2px 0px #fdb400;
        }
    }

    .bottom-container {
        display: flex;
        justify-content: center;

        .button {
            display: flex;
            background: #ff5c00;
            border-radius: 4px;
            min-width: 188px;
            padding: 6px 12px;
            margin-top: 20px;
            align-self: center;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.05em;
            color: #ffffff;
            justify-content: center;
        }
    }
}
