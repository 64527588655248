@use "variables" as variable;

#horizontal-carousel-offer {
  background-color: #ffffff;
  padding-left: 16px !important;
  &.offer-banner-single-container {
    padding-right: 16px;
  }
  .offer-banner {
    width: 85vw !important;
    object-fit: contain;
    margin: 4px 14px 0 0;
    border-radius: 0.5rem;
  }

  .slick-track {
    margin: auto;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 20px;
    margin: 0 0px !important;
    padding: 0;
    cursor: pointer;
  }

  .offer-banner-single-image {
    width: 100% !important;
    object-fit: contain;
    margin: 1vw 2vw 0vw 2vw;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0.5rem;
  }
  &.servicesOfferCarousel {
    background: transparent;
    .slick-slide {
      img {
        height: 150px !important;
      }
    }
  }
}

.height-24vw {
  height: 28vw !important;
}
