@use "variables" as variable;

#user-location-page {
  background-color: #ffffff;
  height: 120vh;
  // height: fit-content;
  // min-height: 100vh;
  .section-break {
    background-color: #eeeff8;
  }

  .input-heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #7A7D99;
    padding-bottom: 8px;
  }

  .header {
    height: 5rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-bar-container {
      display: flex;
      border-radius: 0.4rem;
      background: #eeeff8;
      justify-content: center;
      $width: 95vw;
      width: $width;
      height: calc(variable.$header-height * 0.8);

      .back-button {
        // padding: 0.2rem 0rem;
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
        border: 1px solid #dddff1;
        border-right: 0px !important;
        background-color: #eeeff8;
        box-shadow: none;
        width: calc($width * 0.1);

        .icon {
          color: #7a7d99;
          width: 0.5rem;
          height: 0.8rem;
        }
      }

      .search-bar {
        // padding: 0.2rem 0rem;
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        border: 1px solid #dddff1;
        border-left: 0px !important;
        width: calc($width * 0.9);
        background-color: #eeeff8;
      }

      .search-bar:focus {
        outline: none;
      }
    }

    .user-current-location-container {
      height: fit-content;
      width: 80vw;
      height: 40px;
      // margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F2ECFF;
      border: 1px solid #573996;
      border-radius: 8px;
      // height: 2.5rem;

      .gps-location-icon {
        margin: 0vw 1vw;
        margin-left: 2.5vw;
        width: 1.2rem;
        height: 1.2rem;
      }

      .text {
        margin: 0vw 1vw;
        font-weight: 500;
        font-size: 13px;
        color: #573996;
      }
    }
  }

  .body {
    height: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .content {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0vw 3vw;
      margin-bottom: 0.4rem;

      .text {
        color: #7a7d99;
        font-size: 0.9rem;
      }
    }
  }

  .add-address-button-container{
    
    display: flex;
    align-items: center;
    justify-content: center;

    .add-new-address-button {
      color: #141736 !important;
      font-size: 4vw;
      font-weight: 400;
      padding: 1rem 0.7rem;
      border-radius: 0.5rem;
      background: #eeeff8;
      width: 90%;
      // margin: auto;
      display: flex;
      justify-content: center;
      margin: 20px;
    }
  }

  .user-addresses-container {
    .user-address {
      background-color: #ffffff;
      height: 7.5rem;
      width: 100vw;
      display: flex;
      // align-items: center;

      .form-check-inline {
        display: inline-block;
        margin: 1rem 1rem;
      }

      .form-check-input[type="radio"] {
        width: 1.25rem;
        border: 2px solid #7a7d99;
        height: 1.25rem;
      }

      .form-check-input[type="radio"]:checked {
        box-shadow: none !important;
        background: none;
        border: 2px solid #141736 !important;
      }

      input[type="radio"]:before {
        content: "";
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
      }

      .form-check-input[type="radio"]:checked:before {
        background-color: #141736;
      }

      .item {
        // display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 3.5vw;
        margin-left: 0vw;
        width: 92vw;

        .address {
          .address-type {
            display: block;
            color: #141736;
            font-size: 0.9rem;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            max-width: 60vw;
            text-overflow: ellipsis;
          }

          .address-text {
            display: block;
            color: #7a7d99;
            font-size: 0.9rem;
            overflow: hidden;
            white-space: nowrap;
            max-width: 75vw;
            text-overflow: ellipsis;
          }
        }
      }
      .icon-container {
        .edit-icon{
          position: absolute;
          right: 3%;
          width: 2rem;
          height: 2rem;
          margin-top: 4%;
        }
      }
    }

    .section-break {
      background-color: #eeeff8;
    }
  }

  #add-delivery-address {
    // height: calc(100vh - variable.$footer-height);
    margin-bottom: variable.$footer-height;
    height: 120vh;
    // height: inherit;
    background-color: #ffffff;
    margin-top: 0.5rem;

    .input-heading{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #7A7D99;
      padding-bottom: 8px;
    }

    .alternate-number-text {
      font-size: 4vw;

      @media (min-width: variable.$screen-xs-mobile) {
        font-size: 3.5vw;
      }

      font-weight: 400;
      padding-bottom: 3%;
      align-items: center;
      color: #00b9b7;
    }

    .location-button {
      background: #F2ECFF;
      border: 1px solid #573996;
      border-radius: 8px;
      color: #573996 !important;
      font-size: 3.5vw;
      font-weight: 500;
      padding: 0.8rem 0.8rem;
      margin-bottom: 8%;
      text-align: center;
      letter-spacing: 0em;

      .location-icon {
        height: 5vw;
      }
    }

    input[type="checkbox"] {
      width: 0.9rem;
      height: 0.9rem;
      margin-right: 3%;
    }

    .input[type="checkbox"]:checked + label {
      background-color: #573996;
    }

    .input-box {
      background-color: #eeeff8;
      color: black !important;
      border: 0;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      display: inline-block;
      margin-bottom: 4%;
    }

    .on-input-focus{
      border: 1.5px solid #573996;
    }

    .input-box::-moz-placeholder {
      font-size: 3vw;
    }

    .input-box::-webkit-input-placeholder {
      font-size: 4vw;
    }

    .address-type {
      color: #7a7d99;
      font-size: 0.75rem;
    }

    .address-types {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4%;
      width: 60vw;

      .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .radio_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
      }

      input[type="radio"] {
        appearance: none;
        display: none;
      }

      label {
        font-size: 0.85rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeff8;
        width: 20vw;
        height: 1.8rem;
        margin: 0vw 1vw;
        text-align: center;
        border-radius: 9999px;
        overflow: hidden;
        transition: linear 0.3s;
        color: #6e6e6edd;
      }

      .first-label {
        margin-left: 0px !important;
      }

      input[type="radio"]:checked + label {
        background: #573996;
        border: 1px solid #573996;
        color: #ffffff;
        transition: 0.3s;
      }
    }

    .default-address {
      color: #141736;
      font-size: 0.85rem;

      .default-address-checkbox {
        accent-color: #00b9b7;
        color: #141736;
        .form-check-input:checked {
          background-color: #573996;
          border-color: #573996;
        }
      }
    }
  }

  .save-address-button {
    background-color: #573996;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 1rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    // incase, we want to move back to button back to bottom
    // position: fixed;
    // bottom: 0;
  }
}
