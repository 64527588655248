@use "variables" as variable;

#order-details-page {
  background-color: #eeeff8;
  height: 100vh;

  .order-details-container {
    #ordered-product-preview-card {
      background-color: #ffffff;
      height: 8.5rem;
      width: 100vw;

      .product-image-container {
        width: 30vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .product-image {
          width: 80%;
          height: 90%;
          object-fit: contain;
        }
      }

      .body {
        max-width: 72vw;
        padding: 0 3%;

        .product-name {
          color: #141736;
          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1rem;
          // width: 95%;
          text-align: left;
          margin-bottom: 0.5rem !important;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        .price {
          color: #141736;
          font-size: 0.9rem;
          font-weight: 700;
        }

        .order-status {
          color: #7a7d99;
          font-size: 0.8rem;
        }
      }
    }
  }

  .ordered-products-list-container {
    background-color: #ffffff;
    width: 100vw;
    height: 7rem;
    padding: 3vw 3vw;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .product {
      height: 5.5rem;
      padding: 2vw, 2vw !important;
      flex-shrink: 0;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      overflow: hidden;
      border-radius: 0.5rem;
      width: 26vw;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position-x: center !important;

      .overlay {
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      }

      .centered {
        color: #ffffff;
        text-align: center;
        position: relative;
        transform: translate(-50%, -50%);
        top: -50%;
        right: -50%;
        font-size: 0.9rem;
      }
    }
  }

  .return-button-container {
    width: 100vw;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    .text {
      color: #141736;
      font-size: 0.9rem;
    }
  }

  .delivery-address-container {
    background-color: #ffffff;
    width: 100vw;
    height: fit-content;
    color: #141736;
    font-size: 0.85rem;
    line-height: 1.4rem;
    padding: 3vw 3vw;

    .header {
      font-weight: 500;
      font-size: 0.75rem;
      color: #7a7d99;
    }

    .address {
      color: #7a7d99;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .name {
      font-weight: 500;
    }

    .mobile {
      color: #7a7d99;

      span {
        font-weight: 500;
        color: #141736 !important;
      }
    }
  }

  .cart-details {
    background-color: #ffffff;

    .cart-details-container {
      .details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0vw 3vw;
        padding-top: 2vw;

        .key {
          color: #7a7d99;
          font-size: 0.85rem;
        }

        .value {
          color: #141736;
          font-size: 0.85rem;
        }

        .green-color {
          color: #2ca378 !important;
        }
      }

      .total {
        font-weight: 500;
        margin: 2vw 0vw !important;
        height: 2.5rem;
        border-top: 1px solid #eeeff8;
        border-bottom: 1px solid #eeeff8;
        padding: 0vw 2vw;
      }
    }
  }

  // Progress bar

  .order-status-container {
    .order-id {
      padding-left: 2rem;
    }

    .order-delivered-status {
      padding-left: 2rem;
      margin-bottom: 0.5rem;
    }

    #order-detail-list {
      padding-top: 3.2% !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    .order-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7a7d99;
    }

    #order-status-margin {
      margin-bottom: 2rem;

      .track-order-button {
        width: 46%;
        background-color: #5427a6;
        color: #ffffff !important;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0.8rem 0.7rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
      }
      .order-placed-date {
        color: #7a7d99;
        font-size: 0.8rem;
      }
    }

    background-color: #ffffff;

    .vertical-progress {
      padding: 0;
      padding-top: 3px;

      .node {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        transition: all 1000ms ease;
      }

      .activated {
        box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
      }

      .divider {
        height: 2.5rem;
        width: 2px;
        margin-left: 4px;
        transition: all 800ms ease;
      }

      .width-1 {
        width: 1px;
        margin-left: 4.5px;
      }

      li p {
        display: inline-block;
        margin-left: 25px;
      }

      li {
        list-style: none;
        line-height: 1px;
      }

      .blue {
        background-color: rgba(82, 165, 255, 1);
      }

      .green {
        background-color: #5427a6;
      }

      .red {
        background-color: rgba(255, 148, 148, 1);
      }

      .grey {
        background-color: #dddff1;
      }
    }
  }
}
