@use 'variables'as variable;

#reward-system-header {
  margin-bottom: 20px;

  .reward-system-header {
    width: auto;
    height: auto;
    background-color: #5427A6;
    overflow: auto;
    padding: '40px';
  }

  .reward-system-header-asset {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .reward-system-header-text {
    overflow: auto;
    color: #FFFFFF;
    font-size: 15px;
    font-family: Roboto;
    text-align: left;
  }

  .reward-system-header-heading {
    overflow: auto;
    color: #FFFFFF;
    font-size: 20px;
    font-family: Roboto;
    text-align: left;
  }

  .reward-system-header-buttons {
    padding: 0.5rem;
  }

  .header-summary-section {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 90%;
    height: 6em;
    position: relative;
    z-index: 2;
    margin-top: -35px;
  }
}

