@use "variables" as variable;

#login-page {
  // background-color: #ffffff;

  .login-section {
    margin-top: -20px;
    background-color: #ffffff;
    // border: 2px solid #141736;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    position: relative;
    z-index: 2;
    padding-top: 2.5rem;

    .truecaller-button {
      background-color: #0087ff;
      color: #ffffff !important;
      font-size: 0.9rem;
      font-weight: 500;
      padding: 1rem 0.7rem;
      border-radius: 0.5rem;
      display: inline-block;
    }

    .truecaller-icon {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      position: relative;
      left: -20%;

      @media (max-width: variable.$screen-xs-mobile) {
        left: -17%;
      }
    }

    .login-button {
      background-color: #573996;
      color: #ffffff !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.9rem 0.7rem;
      border-radius: 0.5rem;
      display: inline-block;
      width: 27vw;
      margin-left: 0px !important;
      // margin-top: -8px !important;
      // incase, we want to move back to button back to bottom
      // position: fixed;
      // bottom: 0;
      @media only screen and (max-width: 400px) {
        padding: 0.9rem 0.5rem;
      }
    }

    .phone-no {
      margin-left: 2.5vw;
    }

    .country-code-login {
      // width: 15%;
      // margin-left: 5%;
      background-color: #eeeff8;
      color: black !important;
      border: 0;
      padding: 0.9rem 0.2rem;
      border-radius: 0.5rem;
      text-align: center;
      display: inline-block;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .number-login {
      width: 100%;
      background-color: #eeeff8;
      color: #573996 !important;
      border: 0;
      padding: 0.9rem 1rem;
      border-radius: 0.5rem;
      margin-left: 0%;
      // display: inline-block;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 0px;
    }

    .verification-code-login {
      // background-color: white;
      padding: 0 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        /* Secondary */

        color: #7a7d99;
      }
    }

    .edit-button-login {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: #573996;
      margin-bottom: 1px !important;
    }

    .resend-button {
      font-size: 13px;
      color: #573996;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
    }

    .otp-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      margin-top: 1vw;
      margin-bottom: 5vw;
      color: black;
    }

    // CSS for seprated OTP input fields
    // .otp-input {
    //   border-bottom: gray 1px solid;
    //   border-top: 0;
    //   border-right: 0;
    //   border-left: 0;
    //   // padding: 1rem 1.6rem;
    //   width: 13vw !important;
    //   height: 3.5rem;
    //   margin-right: 0.6rem;
    //   color: black;
    // }

    .otp-input {
      border-bottom: #dddff1 2px solid;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      //margin-top: 6.25%;
      // padding: 1rem 1.6rem;
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
      //height: 3.75rem;
      margin-right: 0.6rem;
      color: black;
      outline: 0;

      font-family: Roboto;
      letter-spacing: 0.4em;

      width: 77%;
      background-color: #eeeff8;
      color: #141736 !important;
      border: 0;
      padding: 0.9rem 1rem;
      border-radius: 0.5rem;
    }

    .otp-focus {
      border-bottom: black 1px solid;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0;
      padding: 1rem 1.6rem;
      margin-right: 0.6rem;
      color: black;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
    }
  }
  .slick-slider {
    .slick-list {
      .slick-slide {
        .login-carousel-container {
          img {
            // object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
