@use 'variables'as variable;

#add-to-cart-toast {

    .toast-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }

    .button {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #00B9B7;
    }
}