@use "variables" as variable;

#search-bar-container {
  // padding-bottom: 16px;
  // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.12);

  .search-bar-box {
    // margin: 0 0.75rem 0px 0.75rem;
    height: calc(variable.$header-height * 0.7);
    // padding: 4.5rem 0.75rem 0.75rem 0.75rem;
    border: 1.5px solid #c9c9c9;
    display: flex;
    align-items: center;
    padding: 0.6rem;
    background: #f1f0f5;
    // background: #ffffff;
    // border: 1px solid #dddff1;
    box-shadow: 0px 0px 3px rgba(22, 14, 14, 0.16);
    border-radius: 8px;
    padding-left: 2%;
    width: 100%;

    .search-bar-input {
      //box-sizing: border-box;
      background: #f1f0f5;
      width: 100%;
      border: none;
      padding-left: 8px;
      outline-style: none;

      input:focus {
        border: none;
        outline-style: none;
      }
      &.scroll-down-search-bar {
        display: none;
      }
    }

    .search-icon {
      height: 16px;
      padding-left: 8px;
    }
  }
}

#search-bar-header {
  display: flex;
  justify-content: center;

  .ribbon {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 14.25rem;

    // img {
    //   height: 2.25rem;
    // }
    width: 100vw;
    height: 5rem;
  }
}
