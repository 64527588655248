@use "variables" as variable;

#trending-searches-and-history {
    background-color: #ffffff;

    .container {
        margin-top: 5rem;
        .search-history-items,
        .search-trending-items {
            display: flex;
            align-items: center;
            overflow-x: scroll;

            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            .history-container,
            .trending-searches-container {
                display: flex;
                align-items: center;
                background: #ffffff;
                border: 1px solid #e5e5e6;
                border-radius: 40px;
                margin-right: 12px;
                padding: 8px 12px;
            }
        }

        .history-container {
            display: flex;
            align-items: center;
            margin: 1rem 0rem 0;
            overflow-wrap: break-word;

            .history-icon {
                width: 20px;
                height: 20px;
            }
        }

        .trending-searches-container {
            margin: 1rem 0rem;

            .trending-icon {
                width: 20px;
                height: 20px;
            }
        }

        .text {
            margin-left: 0.6rem;
            color: #67696c;
            font-size: 0.95rem;
            display: block;
            width: max-content;
        }
        .top-brands-container {
            margin-top: 8px;
            .top-brands-carousel {
                margin: 1rem 0rem;
                display: flex;
                overflow-x: scroll;

                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
                }
                .top-brand-item {
                    margin-right: 16px;
                    width: 60px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span {
                        font-size: 13px;
                        margin-top: 8px;
                        text-align: center;
                        font-weight: 600;
                        color: #000;
                    }
                }
            }
        }
    }
}
