@use "variables" as variable;

#footer-component {
  .footer {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 20;
  }

  .footer-font {
    font-family: Roboto;
    font-size: 16px;
    background-color: #573996;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    // vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 20px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .footer-font-share {
    background: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    color: #573996;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    .share-button {
      height: 24px;
      margin-right: 8px;
    }
    .share-button-loader {
      width: 2rem;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      // display: inline-flex;
      background: transparent;
      /* Seperator 2 */

      border: none;
      border-radius: 6px;

      .share-loader {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: -0.125em;
        border: 0.15em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: 0.75s linear infinite spinner-border;
        animation: 0.75s linear infinite spinner-border;
        color: #573996 !important;
      }
    }
  }

  .border-top {
    border-top: 1px solid #eeeff8;
  }

  .product-details-footer {
    border-top: 1px solid #eeeff8;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    .loader-container {
      .spinner {
        > div {
          width: 13px;
          height: 13px;
          margin-left: 6px;
          background-color: #ffffff;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
