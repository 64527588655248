@use "variables" as variable;

#product-details-page {
  max-width: 100vw;

  #product-details-body {
    margin-bottom: 3.5rem;
    background-color: #eeeff8;

    .coins-applied-container{
      background-color: #ffffff;
      margin-top: 0;
      border: solid 1px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-color: #0000001A;
      display: flex;
      align-items: center;
      border-radius: 5px;
  
      .coins-applied-text{
        font-weight: 700;
        font-size: 1.1rem;
        color: #5427A6;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .coins-toggle-container{
        display : flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 2rem;

        .coins-toggle-button{
          margin-left: 3rem;
        }
    
        .coins-toggle-button.react-toggle .react-toggle-track {
          background-color: #D3D3D3;
        }
    
        .coins-toggle-button.react-toggle--checked .react-toggle-track {
          background-color: #5427A6;
        }
      }

    }  

    .carousel-container {
      // max-height: 20rem;
      position: relative;
      background-color: #ffffff;
      .slick-dots li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 20px;
        margin: 0 0px !important;
        padding: 0;
        cursor: pointer;
      }

      #product-detail-carousel {
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16rem;
        //width: 90vw;
        padding: 1rem;
        // margin-top: -15%;
        // max-height: 16rem;
        // overflow-y: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .product-details-single-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16rem;
      //width: 90vw;
      padding: 1rem;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .product-name {
      width: 98%;
      font-size: 1rem;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      margin-bottom: 4px;
    }

    .product-discounted-price {
      font-weight: 800;
      font-size: 1.3rem;
      color: #141736;
      margin-right: 3vw !important;
    }

    .product-price {
      color: #7a7d99;
      font-size: 1.3rem;
      margin-right: 3vw;
      text-decoration: line-through;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .original-price-no-discount{
      font-weight: 800;
      font-size: 1.3rem;
      color: #141736;
    }

    .coin-container{
      border : solid 1px #5428A6;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding : 5px 10px;
      background-color: #5428A60D;
    }

    #strike-color {
      color: #7a7d99;
      font-size: 1.3rem;
      text-decoration: line-through;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .offer-discount {
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      color: #00b9b7;
    }

    .variants-container {
      width: 100vw;
      min-height: 4rem;
      height: max-content;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .body {
        padding: 0 8px 3vw;
        width: 100vw;

        .variants {
          height: 80%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          // .address-type {
          //   color: #7A7D99;
          //   font-size: 0.75rem;
          // }

          .variant {
            margin: 4px 4px;
            font-weight: 500;
          }

          // .address-types {

          // margin-bottom: 4%;
          // width: 60vw;

          .container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .radio_container {
            display: flex;
            justify-content: space-around;
            align-items: center;
            box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
          }

          input[type="radio"] {
            appearance: none;
            display: none;
          }

          label {
            font-size: 0.85rem;
            display: flex;
            min-width: max-content;
            padding: 0px 12px;
            justify-content: center;
            align-items: center;
            width: 20vw;
            height: 2.25rem;
            // margin: 0vw 2vw;
            text-align: center;
            border-radius: 0.5rem;
            overflow: hidden;
            transition: linear 0.3s;
            color: #141736;
            border: 1px solid #dddff1;
            border-radius: 0.5rem;
          }

          .first-label {
            margin-left: 0px !important;
          }

          input[type="radio"]:checked + label {
            background: #eeeff8;
            border-radius: 8px;
            color: #141736;
            transition: 0.3s;
          }

          // }

          // .default-address {
          //   color: #141736;
          //   font-size: 0.85rem;

          //   .default-address-checkbox {
          //     accent-color: #00B9B7;
          //     color: #ffffff;
          //   }
          // }
        }
      }
    }

    .basic-details-product {
      background-color: white;
      border-radius: 8px;
      margin: 10px;
      padding: 10px;
      display: flex;
      margin-left: 5vw;
      // align-items: center;
      margin-bottom: 0;
      flex-direction: row;

      .left-container {
        width: 90%;
      }

      .fade {
        opacity: 1;
        transition: opacity 0.7s ease-in-out;
      }
      
      .fade-hidden {
        opacity: 0;
      }

      .hide{
        display: none;
      }

      .percentage-off {
        // margin-top: 0.1rem;
        color: #fff;
        // font-size: 0.9rem;
        font-size: 3vw;
        font-weight: 500;
        word-spacing: 0.5vw;
        // padding: 0 3%;
        // margin-top: auto;
        justify-self: center;
        z-index: 5;

        span {
          color: #fff;
          font-weight: 400;
          display: block;
          justify-content: center;
        }
      }

      .right-container {
        width: 10%;

        [id="toggle-heart"] {
          position: absolute;
          left: -100vw;

          &:checked + label {
            color: #e2264d;
            will-change: font-size;
            // animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
          }
        }

        [for="toggle-heart"] {
          align-self: center;
          color: #aab8c2;
          font-size: 1.3rem;
          cursor: pointer;
          justify-content: end;
        }

        @keyframes heart {
          0% {
            transform: scale(0.3);
          }

          100% {
            transform: scale(1);
          }
        }
      }
    }

    .add-to-wishlist-icon-container {
      border: 1px solid #dddff1;
      box-sizing: border-box;
      border-radius: 0.5rem;
      background-color: #ffffff;
      width: 2.5rem;
      height: 2.5rem;
      position: fixed;
      right: 8%;
      top: 30%;

      .wishlist-icon {
        background-color: #aeb0c2;
      }
    }

    .section-break {
      background-color: #eeeff8;
    }

    #delivery-address-details {
      background-color: #ffffff;

      .location-icon,
      .right-arrow-icon {
        // font-size: 1rem;
        height: 20px;
        // padding: 0vw 1vw;
      }

      .right-arrow-icon {
        margin-right: 2vw;
      }

      .location-icon {
        margin-right: 2vw;
        margin-left: 2vw;
      }

      .location-details {
        font-size: 0.9rem;
        width: 70vw;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 500;
      }

      .powered-by-container {
        width: 40vw;
        margin-right: 5vw;

        @media (max-width: 360px) {
          width: 55vw;
        }

        .powered-by {
          color: #aeb0c2;
          font-size: 0.7rem;
        }

        .company-logo {
          width: 1rem;
          height: 1rem;
          margin: 0vw 0.25rem;
        }

        .company-name {
          color: #141736;
          font-size: 0.8rem;
        }
      }
    }

    .product-meta-details-container {
      .detail-container {
        border-right: 1px solid #eeeff8;
        width: 33vw;
        // display: flex;
        // justify-content: center;

        &:last-child {
          border: 0;
        }

        .product-meta-detail-icon {
          height: 1.5rem;
          width: 1.5rem;
        }

        .product-meta-details-text {
          // width: 30%;
          font-size: 13px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: center;
          padding-left: 0.7rem !important;
          padding-right: 0.7rem !important;
        }
      }
    }

    .accordion-body {
      max-width: 96vw;
      overflow: hidden;
    }

    .accordion-button:not(.collapsed) {
      color: #000000;
      background-color: #ffffff;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    }

    .accordion-button:focus {
      border-color: #ffffff;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    }

    .accordion-button:hover {
      z-index: 0;
    }

    .share-button-loader {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      // display: inline-flex;
      background: transparent;
      /* Seperator 2 */

      border: none;
      border-radius: 6px;

      .share-loader {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: -0.125em;
        border: 0.15em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: 0.75s linear infinite spinner-border;
        animation: 0.75s linear infinite spinner-border;
        color: #573996 !important;

        .spinner-border {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          vertical-align: -0.125em;
          border: 0.2em solid currentColor !important;
          border-right-color: transparent;
          border-radius: 50%;
          -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
        }
      }
    }
  }

  .accordion-item {
    border: none;
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-weight: 500;
    font-size: 1.1rem;
    box-shadow: none !important;
  }

  .accordion-item:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

#discount-tag {
  top: 10px;
  left: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  z-index: 10;

  .fade {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-hidden {
    opacity: 0;
  }

  .sale-tag {
    top: 0;
    left: 0;
    position: absolute;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 50%;
    width: 3rem !important;
    height: auto !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .percentage-off {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    word-spacing: 0.5vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    width: 100%;
    line-height: 1.2;
    margin-top: 4px;
    text-align: center;
    margin-top: 12px;
    span {
      color: #fff;
      font-weight: 600;
      display: block;
      justify-content: center;
    }
  }
}
