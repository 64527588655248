@use 'variables'as variable;

#step-by-step-progress {

    background-color: #ffffff;

    .container {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        padding: 0.8rem 8vw;

        .steps {

            .max-width {
                min-width: 70px;
                overflow: hidden !important;
                text-overflow: ellipsis !important;               
                -webkit-line-clamp: 1 !important;                
                -webkit-box-orient: vertical !important;         
            }

            .step {
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 0px 2vw;
                flex-direction: column;

                .completed {
                    background: rgba(0, 185, 183, 0.1) !important;
                    border: 1px solid #00B9B7 !important;
                }

                .tick-icon {
                    color: #00B9B7
                }

                .icon-completed {
                    color: #00B9B7 !important;
                }

                i {
                    color: #AEB0C2;
                    font-size: 4vw;
                }

                .counter {
                    display: flex;
                    background: #EEEFF8;
                    border-radius: 50%;
                    border: 1px solid #DDDFF1;
                    width: 8vw;
                    height: 8vw;
                    font-size: 3vw;
                    align-items: center;
                    justify-content: center;
                }

                .text {
                    font-size: 2.5vw;
                    margin-top: 0.5rem;
                    text-align: center;
                    overflow: hidden;
                    overflow-wrap: break-word;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }


        }

    }



}