#invite-friends-banner {
    background-color: #FFE8C7;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;

    img {
        display: block;
        width: 100%;
        padding-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    h3 {
        font-size: 16px;
        font-family: Roboto;
        text-align: center;
        color: #5427A6;
        padding-top: 10px;
        margin-bottom: 20px;
    }
}

#redeem-coins-banner {
    background-color: #DEF8D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;

    img {
        display: block;
        width: 100%;
        padding-top: 26px;
        margin-left: auto;
        margin-right: auto;
    }

    h3 {
        font-size: 16px;
        font-family: Roboto;
        text-align: center;
        color: #5427A6;
        padding-top: 10px;
        margin-bottom: 20px;
    }
}

#login-banner {
    border-radius: 10px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
    display: flex;
    padding: 0;
    
    .login-banner-image {
        padding: 0;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        margin-left: auto;
    }

    .login-banner-text {
        flex: 3;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 18px;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        font-family: Roboto;
        text-align: left;
        color: white;
        margin-bottom: auto;
        margin-top: auto;
    }
}

#coins-summary {
    width: 100%;
    margin: 0;
    padding: 0;


    .coins-summary-text {
        display: flex;
        align-items: flex-end;
    }

    .coins-summary-text h3 {
        font-size: 16px;
        font-weight: 400;
        font-family: Roboto;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto
    }

    .coins-summary-passbook-text {
        margin-top: 0.25em;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
    }

    .coins-summary-passbook-text h3 {
        font-size: 13px;
        font-weight: 400;
        font-family: Roboto;
        text-align: right;
        color: #7A7D99;
        margin-bottom: 0;
    }


    .coins-summary-image {
        margin: 0;
        padding: 0;
        text-align: right;
    }

    img {
        width: 50px;
        margin: 0;
        text-align: right;
    }

    .coins-summary-coins {
        margin-top: auto;
        margin-bottom: auto;
    }


    h2 {
        margin: 0;
        padding: 0;
        font-size: 28px;
        font-weight: 700;
        font-family: Roboto;
        text-align: right;
    }
}