@use "variables" as variable;

#address-page {
  height: fit-content;
  min-height: 100vh;
  background-color: #ffffff;
  margin-bottom: variable.$footer-height;

  .section-break {
    background-color: #eeeff8;
  }

  #add-delivery-address {
    margin-top: 0.5rem;

    .input-heading{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #7A7D99;
      padding-bottom: 8px;
    }

    .alternate-number-text {
      font-size: 4vw;

      @media (min-width: variable.$screen-xs-mobile) {
        font-size: 3.5vw;
      }

      font-weight: 400;
      padding-bottom: 3%;
      align-items: center;
      color: #00b9b7;
    }

    .location-button {
      background: #F2ECFF;
      border: 1px solid #573996;
      border-radius: 8px;
      color: #573996 !important;
      font-size: 3.5vw;
      font-weight: 500;
      padding: 0.8rem 0.8rem;
      margin-bottom: 8%;
      text-align: center;
      letter-spacing: 0em;

      .location-icon {
        height: 5vw;
      }
    }

    input[type="checkbox"] {
      // width: 20px;
      // height: 20px;
      width: 16px;
      height: 16px;
      margin-right: 3%;
    }

    .input[type="checkbox"]:checked + label {
      background-color: #573996;
    }

    .input-box {
      background-color: #eeeff8;
      color: black !important;
      border: 0;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      display: inline-block;
      margin-bottom: 4%;
    }

    .on-input-focus{
      border: 1.5px solid #573996;
    }

    .input-box::-moz-placeholder {
      font-size: 3vw;
    }

    .input-box::-webkit-input-placeholder {
      font-size: 4vw;
    }

    .address-type {
      color: #7a7d99;
      font-size: 0.75rem;
    }

    .address-types {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4%;
      width: 60vw;

      .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .radio_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
      }

      input[type="radio"] {
        appearance: none;
        display: none;
      }

      label {
        font-size: 0.85rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeff8;
        width: 20vw;
        height: 1.8rem;
        margin: 0vw 1vw;
        text-align: center;
        border-radius: 9999px;
        overflow: hidden;
        transition: linear 0.3s;
        color: #6e6e6edd;
      }

      .first-label {
        margin-left: 0px !important;
      }

      input[type="radio"]:checked + label {
        background: #573996;
        border: 1px solid #573996;
        color: #ffffff;
        transition: 0.3s;
      }
    }

    .default-address {
      color: #141736;
      font-size: 0.85rem;
      display: flex;
      align-items: center;

      .default-address-checkbox {
        width: 50vw;
        accent-color: #573996;
        color: #141736;
        .form-check-input:checked {
          background-color: #573996;
          border-color: #573996;
        }
      }
    }
  }

  .save-address-button {
    background-color: #573996;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 1rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    // incase, we want to move back to button back to bottom
    // position: fixed;
    // bottom: 0;
  }

  .deactivated-saved-address-button{
    background-color: #AEB0C2;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 1rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
  }

  #list-address {
    .labl {
      .edit-icon {
        position: absolute;
        right: 3%;
        width: 2rem;
        height: 2rem;
        margin-top: 4%;
      }

      .radio-button {
        position: absolute;
        left: 3%;
        margin-top: 6%;
        accent-color: #141736;
        width: 1.2rem;
        height: 1.2rem;
      }

      .radio {
        cursor: pointer;
        margin: 2px 0;

        .radio.selected {
          border-color: #141736;
        }
      }

      .address {
        padding: 1rem 12.5vw;

        .name {
          color: #141736;
          font-size: 0.85rem;
        }

        .address-details {
          color: #7a7d99;
          // clear: both;
          // display: inline-block;
          // width: 100%;
          font-size: 0.85rem;
        }

        .address-type {
          margin-top: 0.3rem;
          background-color: #eeeff8;
          color: #141736;
          width: 15vw;
          height: 2rem;
          padding: 0.1vw;
          border-radius: 0.5rem;
          font-size: 0.85rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        border-bottom: 1px solid #eeeff8;
      }
    }

    .add-new-address-button {
      color: #141736 !important;
      font-size: 4vw;
      font-weight: 400;
      padding: 1rem 0.7rem;
      border-radius: 0.5rem;
      background: #eeeff8;
    }
  }

  .continue-button {
    background-color: #141736;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 1rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    position: fixed;
    bottom: 0;
  }
}
