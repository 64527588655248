@use "variables" as variable;

#services-container {
  background-color: #1c1c1c;
  .service-wrapper {
    margin: 0 1rem;
    background-color: #ffffff;
    border-radius: 16px;
    padding-top: 0.35rem;
    .background-gradient {
      height: 94px;
      background: linear-gradient(180deg, #f4efff 0%, #e2d3ff 100%);
      border-radius: 14px;
      margin: 0 0.35rem;
    }
    .services {
      margin-top: -94px;
    }
    #offer-header {
      color: #573996;
      background-color: transparent;
      letter-spacing: 0.05em;
      margin-bottom: 8px;
      .heading {
        font-weight: 900;
      }
    }
    .load-more-button {
      width: 100%;
      height: auto;
      border-radius: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: #573996;
      color: #ffffff !important;
      padding: 8px 18px;
      transition: 0.3s;
      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .service {
    width: 32vw;
    height: 72px;
    padding: 2vw 1.5vw;
    height: fit-content;

    @media (max-width: variable.$screen-xs-mobile) {
    }

    .service-banner {
      width: 100%;
    }
  }

  .service-1x {
    width: 33%;
    .service-banner {
      width: 100%;
      // height: 5.7rem;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }
  }

  .service-2x {
    width: 50%;
    .service-banner {
      width: 100%;
      // height: 6.5rem;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }
  }

  .service-3x {
    width: 100%;
    .service-banner {
      width: 100%;
      // height: 6.5rem;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }
  }

  :is(.service):empty {
    position: relative;
    overflow: hidden;
  }

  :is(.service):empty:before {
    --gradient: linear-gradient(to right, #ffffff00, #ffffffff, #ffffff00);
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 100%;
    background: var(--gradient);
    transform: translateY(-50%) skew(-20deg);
    animation: loading 1s ease-in-out infinite;
  }

  @keyframes loading {
    from {
      left: -100%;
    }
    to {
      left: 120%;
    }
  }

  .service-container {
    display: flex;
    align-items: center;
    padding: 20px 0 4px;
    padding-left: 20px;
    overflow-x: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .service-image-container {
      margin-right: 16px;
      img {
        height: 150px;
      }
    }
  }

  .services-carousel {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding: 20px 0 4px;
    padding-left: 16px;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .service-image-container {
      margin-right: 16px;
      img {
        height: 220px;
      }
    }
  }
}
