@use "variables" as variable;

#product-header {
  .heading {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 800;
  }

  .sub-heading {
    font-size: 0.9rem;
    color: #7a7d99;
    font-weight: 400;
  }

  .circle-right-arrow-icon {
    height: 18px;
    width: 18px;
  }
}

.banner-carousel-products {
  .heading {
    font-style: normal !important;
    font-size: 1.125rem !important;
  }
}

.see-all-btn {
  background-color: #5427a6;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 30px;
  width: 20vw;
  padding: 5px;
  font-size: 0.85rem;
  font-weight: 500;
}
a.see-all-link {
  text-align: right;
}
