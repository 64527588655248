@use "variables" as variable;

#product-quick-preview-container {
    background-color: #ffffff;
    overflow: hidden;

    .section-products {
        .product-carousel {
            &:last-child {
                padding-top: 0;
            }
        }
    }

    .view-more-products-button {
        background-color: #ffffff;
        color: #141736 !important;
        font-size: 4vw;
        font-weight: 400;
        padding: 0.9rem 0.7rem;
        border-radius: 0.5rem;
        border: 1px solid #dddff1;
        border-radius: 8px;
        width: 100%;
    }
    .slick-slide {
        padding: 14px 4px;
    }
    &.top-selling-products {
        padding-bottom: 8px;
    }
    &.banner-carousel-products {
        .discount-tag {
            display: none;
        }
        .section-products {
            > div.container {
                padding-left: 0;
            }
        }
    }
}
