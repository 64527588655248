@use "variables" as variable;

#bottom-fixed-bar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  padding: 16px 24px;
  color: #141736;
  border-top: 1px solid #cfcfcf;
  p {
    color: #555555;
    margin-bottom: 4px;
    font-size: 3vw;
  }
  h5 {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 5vw;
  }
  .free-delivery-tag {
    color: #3e852b;
    background: #e3f5db;
    border-radius: 4px;
    font-size: 3.3vw;
    padding: 3px 9px;
    font-weight: 500;
    margin-left: 12px;
    // background: #c4ebdc;
    // background-image: linear-gradient(92.28deg, #c4ebdc -0.68%, #e2ffe4 100%);
    // background-repeat: no-repeat;
    // background-size: 400px 1004px;
    // display: inline-block;
    // position: relative;
    // animation: placeholderShimmer 2s linear -2s infinite forwards;

    // -webkit-animation-duration: 2s;
    // -webkit-animation-fill-mode: forwards;
    // -webkit-animation-iteration-count: infinite;
    // -webkit-animation-name: placeholderShimmer;
    // -webkit-animation-timing-function: linear;
    // -webkit-animation-delay: 250ms;
  }
  .button-container {
    background: #573996;
    box-shadow: 0px 0px 18px rgba(116, 108, 108, 0.1);
    border-radius: 8px;
    color: #ffffff;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 3.3vw;
    border: none;
    &:hover {
      background: #3c2272;
    }
    .view-cart-btn {
      &:hover {
        border: none;
      }
      &:focus {
        border: none;
        outline: none;
      }
      i {
        margin-left: 6px;
        font-weight: 600;
      }
    }
  }
  .loader-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .spinner {
      > div {
        background-color: #1ca672;
        width: 4vw;
        height: 4vw;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

// @keyframes placeholderShimmer {
//   0% {
//     background-position: -468px 0;
//   }

//   100% {
//     background-position: 468px 0;
//   }
// }
