@use "variables" as variable;

#cashkaro {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 0 0;
    .img-container {
        background-color: #d9d9d9;
        height: 140px;
        width: 140px;
        border-radius: 16px;
        img {
            border-radius: 16px;
        }
    }
    h3 {
        font-size: 22px;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        max-width: 300px;
        margin: 24px 0 0;
        font-weight: 600;
    }
    .copied-text {
        font-size: 24px;
        color: #3847ae;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin-left: 8px;
    }
    h4 {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.61);
        font-weight: 400;
        max-width: 200px;
        text-align: center;
        margin: 16px 0 32px;
        a {
            color: #1194f6;
        }
    }
    .coupon-code-container {
        background-color: #00b9b7;
        padding: 14px 10px;
        border-radius: 12px;
        margin-bottom: 12px;
        .coupon-code {
            font-weight: 600;
            font-size: 18px;
            text-transform: uppercase;
            border: 1px dashed #ffffffa6;
            color: #fff;
            padding: 6px 6px;
            border-radius: 8px;
        }
        .copy-btn {
            background-color: rgba(0, 0, 0, 0.38);
            border-radius: 8px;
            padding: 6px 14px;
            color: #fff;
            margin-left: 12px;
            cursor: pointer;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            border: none;
            outline: none;
            &:hover,
            &:focus {
                border: none;
                outline: none;
            }
            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
    .continue-text {
        position: absolute;
        bottom: 50px;
    }
    .redirection-link {
        color: #1194f6;
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}
