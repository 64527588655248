#transactions {


    .transaction-row {
        display: flex;
    }

    .transaction-text-col {
        flex: 1;
    }

    .transaction-coins-col {
        
    }

    p {
        font-size: '14px';
        font-weight: 700;
        font-family: Roboto;
        margin: 0;
        padding: 0;
    }
}