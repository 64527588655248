@use "variables" as variable;

html,
body {
  padding: 0;
  margin: 0;
  font-family: variable.$font-family-roboto-regular;
  background-color: #ffffff;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

:focus-visible {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.load-more-button {
  background-color: #eeeff8;
  color: #141736 !important;
  padding: 0.3rem 0.7rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  display: inline-block;
  height: 2rem;
}

.button-container {
  border: 1px solid #dddff1;
  border-radius: 0.5rem;
  padding: 4px 12px;
}

.text-ellipsis-60 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60vw;
  text-overflow: ellipsis;
}

.img-responsive {
  display: initial;
}

.img-max-100 {
  max-width: 100%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.line-break {
  height: 1px;
  flex: 1;
  margin: 0 0 0rem 0;
  background-color: #dddff1;
}
.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none !important;
}

.header-text {
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  // margin-left: 4vw;
  // color: #7a7d99;
  width: 55vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #141736;
}

.bold-5 {
  font-weight: 500 !important;
}

.bold-7 {
  font-weight: 700;
}

.layout-children {
  margin-top: variable.$header-height-rest;
}

#home-page {
  .layout-children {
    margin-top: variable.$header-height;
  }
}

#section-break {
  background-color: #ffffff;
}

.product-not-available {
  opacity: 40%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.text-selection-none {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

// https://fkhadra.github.io/react-toastify/api/toast
// Toast root container
.Toastify__toast-container {
  opacity: 0.9 !important;
  // top: 10vh !important;
  bottom: 12vh !important;
  max-height: min-content !important;
  overflow-y: hidden;
}

// className	string	Add optional classes to the container
.toast-container {
  height: inherit !important;
  width: 90vw !important;
  background-color: #000000 !important;
  border-radius: 0.5rem !important;
  color: #ffffff !important;
  margin: auto !important;
}

.Toastify__toast {
  height: inherit !important;
  width: 90vw !important;
  background-color: #000000 !important;
  border-radius: 0.5rem !important;
  color: #ffffff !important;
  margin: auto !important;
}

.Toastify__toast-body {
  height: inherit !important;
  width: 90vw !important;
  background-color: #000000 !important;
  border-radius: 0.5rem !important;
  color: #ffffff !important;
  margin: auto !important;
}

// bodyClassName	string	Add optional classes to the TransitionGroup container
.transition-group-container {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 0.9rem;
}

.action-button-loader {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.error-message-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  // padding-top: calc(variable.$footer-height - 10%);

  .image {
    width: 28vw;
    height: 28vw;
  }

  .text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: #7a7d99;
    margin-top: 1rem;
    max-width: 80%;
  }
}

.error-container {
  position: absolute;
  top: 35%;
  width: 100vw;
}

.product-not-found-error-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 8%;
}

.get-current-location-loader {
  width: 1.25rem !important;
  height: 1.25rem !important;

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.2em solid currentColor !important;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }
}

.fixed-free-delivery-tag {
  position: fixed;
  top: variable.$header-height;
  z-index: 20;
  width: 100%;
}

.free-delivery-tag {
  background-color: #e3f5db;
  text-align: center;
  font-weight: 400;
  padding: 8px 0;
  font-size: 14px;
  color: #3e852b;
  span {
    font-weight: 600;
  }
}

.lf-player-container {
  background-color: #ffffff;
}

.loader-component {
  &.loader-component {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .spinner {
      > div {
        width: 5vw;
        height: 5vw;
        margin-left: 8px;
        background-color: #1ca672;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.skeleton-container{
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
}

.infinite-scroll-component {
  .loader-component {
    &.loader-component {
      position: inherit;
      margin: 16px 0;
      transform: none;
    }
  }
}

#search-bar {
  &::-webkit-search-cancel-button {
    display: none;
  }
}
