@use "variables" as variable;

.bouncing-loader {
    display: flex;
    justify-content: center;
}

.bouncing-loader > div {
    width: 16px;
    height: 16px;
    margin: 3px 4px;
    border-radius: 50%;
    background-color: #ef733b;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.8;
        transform: translateY(-8px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
    background-color: #e3500b;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
    background-color: #f77d44;
}

.bouncing-loader > div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: #7b4931;
}
