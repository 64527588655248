@use "variables" as variable;

#categories-container {
  position: fixed;
  top: variable.$header-height;
  z-index: 20;
  display: flex;
  max-width: 100vw;
  margin-top: -2px;
  overflow-x: scroll;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border-top: 2px solid #d8d8d8;
  padding-bottom: 6px;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  a {
    > div {
      margin: 0 0.8rem;
      &.active .category-card {
        border-bottom: 4px solid #573996;
        .category-card-title {
          color: #000000;
          font-weight: 700;
        }
      }
    }
  }
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;;
  // /* Firefox */
  // justify-content: space-around;
  // box-sizing: content-box;

  // ::-webkit-scrollbar {
  //   width: 6px;
  //   height: 8px;
  // }

  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 10px red;
  //   border-radius: 10px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   background: red;
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  // }

  // ::-webkit-scrollbar-thumb:hover {
  //   background: #dcdcdc;
  // }

  // .scrollbar-hidden::-webkit-scrollbar {
  //   display: none;
  // }

  .category-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 15vw !important;
    padding: 0.8rem 0rem 0.2rem;
    background: #ffffff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      //box-shadow: 0px 2px 6px rgba(22, 14, 14, 0.16);
      // transform: translateY(-0.5rem);
    }

    .category-card-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }

    .category-card-title {
      margin-top: 0.2rem;
      font-size: 0.725rem;
      font-weight: 500;
      color: #7a7d99;
      text-align: center;
    }
  }
}
