@use "variables" as variable;

#order-placement-page {
    background-color: #eeeff8;
    height: 100vh;
    $footer-height: 4.7rem;
    margin-bottom: $footer-height;

    .status-container {
        width: 100vw;
        height: 12rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;

        .text {
            color: #141736;
            margin-top: 2rem;
            font-size: 1rem;
        }
    }

    .delivery-estimate-container {
        background-color: #ffffff;
        width: 100vw;
        height: 2.5rem;
        border-bottom: 1px solid #eeeff8;
        display: flex;
        align-items: center;

        .text {
            padding-left: 4vw;
            font-size: 0.9rem;
            color: #141736;
        }
    }

    .delivery-address-container {
        background-color: #ffffff;
        width: 100vw;
        height: fit-content;
        color: #141736;
        font-size: 0.85rem;
        line-height: 1.4rem;
        padding: 3vw 3vw;

        .header {
            font-weight: 500;
            font-size: 0.75rem;
            color: #7a7d99;
        }

        .address {
            color: #7a7d99;
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            -webkit-box-orient: vertical;
        }

        .name {
            font-weight: 500;
        }

        .mobile {
            color: #7a7d99;

            span {
                font-weight: 500;
                color: #141736 !important;
            }
        }
    }

    .share-order-details-container {
        width: 100vw;
        display: flex;
        height: 3rem;
        align-items: center;

        .text {
            display: flex;
            align-items: center;
            width: 75%;
            height: inherit;
            padding-left: 4vw;
            background-color: #ffffff;
            font-size: 0.9rem;
            color: #141736;
        }

        .share-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #00b9b7;
            font-weight: 500;
            width: 25%;
            height: inherit;
            color: #ffffff;
        }
    }

    .footer {
        height: $footer-height !important;

        .continue-shopping-button {
            background-color: #5427a6;
            color: #ffffff !important;
            font-size: 4vw;
            font-weight: 500;
            padding: 1rem 0.7rem;
            border-radius: 0.5rem;
            display: inline-block;
            position: fixed;
            bottom: 0;
        }
    }
}
