@use "variables" as variable;

#category-product-listing-page {
  background-color: #ffffff;
  height: calc(100vh - variable.$header-height);

  .filter-buttons-container {
    background-color: #ffffff;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 2px solid #e9e9e9;

    .button {
      display: flex;
      width: 50vw;
      height: 2.5rem;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;

      &:last-child {
        border-right: 0;
      }

      .sort-icon,
      .filter-icon {
        height: 20px;
        width: 20px;
      }

      .active {
        width: 6px;
        position: relative;
        height: 6px;
        background: #00b9b7;
        border-radius: 50%;
        top: -12%;
        right: 28%;
      }

      .text {
        color: #141736;
        font-size: 0.9rem;
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

  .price-sort-container {
    background-color: #ffffff;
    height: 5.5rem;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: scroll;
    background-repeat: no-repeat;
    transition: all 0.6s ease;
    border-bottom: 1px solid #e9e9e9 !important;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .filter-button {
      position: relative;
      border-radius: 50%;
      width: 4rem;
      margin: 0.5rem 0.3rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      justify-content: space-around;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      // border-color: #00b9b7;
      // border-width: 20px;

      .text {
        position: absolute;
        width: 60%;
        text-overflow: wrap;
        font-weight: 900;
        font-size: 0.75rem;
        text-align: center;
        color: #141736;
      }
    }

    .overlay {
      background: linear-gradient(0deg, rgba(30, 30, 30, 0.5), rgba(30, 30, 30, 0.5));
      outline: 1px solid #00b9b7;
      outline-offset: -1px;
    }

    .image {
      z-index: 3;
    }
  }

  .coins-applied-container{
    background-color: #ffffff;
    margin-top: 0;
    border: solid 1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-color: #0000001A;
    display: flex;
    align-items: center;
    border-radius: 5px;

    .coins-applied-text{
      font-weight: 700;
      font-size: 1.1rem;
      color: #5427A6;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .coins-toggle-container{
      display : flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 2rem;

      .coins-toggle-button{
        margin-left: 3rem;
      }
  
      .coins-toggle-button.react-toggle .react-toggle-track {
        background-color: #D3D3D3;
      }
  
      .coins-toggle-button.react-toggle--checked .react-toggle-track {
        background-color: #5427A6;
      }
    }
  }

  .products-container {
    display: flex;
    flex-flow: row wrap;
    // justify-content: space-around;
    // border: 0px;
    background-color: #ffffff;
    border-top: 1px solid #e9e9e9;
    text-align: center;

    #product-quick-preview-card {
      border-radius: 0;
      box-shadow: none;
      &:nth-child(odd) {
        padding: 0 1vw;
        // margin: 1px 1px 0vw 0vw;
        // margin: 0.75vw;
        border-right: 1px solid #e9e9e9 !important;
        border-bottom: 1px solid #e9e9e9 !important;
      }

      &:nth-child(even) {
        padding: 0 1vw;
        // margin: 0.75vw;
        // margin: 1px 0vw 0vw 0vw;
        border-bottom: 1px solid #e9e9e9 !important;
      }

      width: 49.5vw;
      // height: 20.5rem;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      min-width: 0;
      margin-right: 0 !important;
      // padding: 0 1vw;
      // border-radius: 0.8rem;
      .discount-tag {
        top: 8px;
        left: 8px;
        width: 2.1rem;
        .sale-tag {
          border-top-left-radius: 0.6rem !important;
          border-top-right-radius: 0.6rem !important;
          width: 2.1rem;
        }
        .percentage-off {
          font-size: 0.65rem;
        }
      }

      .card-image-container {
        .card-image {
          object-fit: cover;
          width: 120px;
          display: block;
          margin: auto;
        }
      }

      .product-name {
        font-size: 0.875rem;
      }

      .price {
        padding: 0 3%;
        margin-bottom: 0.25rem;

        .original-price {
          font-size: 1rem;
        }

        .discounted-price {
          font-size: 1rem;
        }
      }

      .share-button-loader {
        width: 36px;
        height: 36px;
      }

      .share-button {
        height: 36px;
        width: 40px;
        padding: 4px;
      }
      #add-to-cart-button {
        height: 36px;
        > div {
          height: 100%;
          width: 100%;
          font-size: 1rem;
          .add-button {
            height: 100%;
            width: 100%;
            font-size: 1rem;
          }
          input {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .category-offers-section {
    .category-offer-row {
      .offer-container {
        margin-bottom: 16px;
        img {
          height: 110px;
          width: 100%;
        }
      }
    }
  }

  .offer-banners-carousel {
    padding: 0 8px;
    background-color: rgb(238, 239, 248);
    .slick-slider {
      .slick-slide {
        .banner-container {
          height: 100%;
          width: 100%;
          padding-left: 4px;
          img {
            width: 100%;
            border-radius: 16px;
            // height: 120px;
          }
        }
      }
      .slick-dots {
        bottom: 6vw;
        li {
          margin: 0;
          width: 10px;
          height: 10px;
          button {
            padding: 0;
            width: 10px;
            height: 10px;
            &::before {
              color: #d9d9d9;
              opacity: 0.5;
              width: 10px;
              height: 10px;
              font-size: 5px;
            }
          }
          &.slick-active {
            button {
              &::before {
                color: #000;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}
