@use "variables" as variable;

#product-not-found-component {
    background-color: #ffffff;
    height: 10rem;

    .body {
        padding: 2rem 0.75rem 1rem 0.75rem;
        display: flex;
        align-items: center;

        .header {
            font-size: 2rem;
            font-weight: 700;
            line-height: 2.3rem;
            letter-spacing: 0em;
            text-align: left;
            color: #dddff1;
            margin-bottom: 0;
        }
    }

    .footer {
        margin: 0.75rem 0.75rem;
        padding-bottom: 1.5rem;

        .text {
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #7a7d99;
            width: 55%;
        }

        .button-container {
            border: 1px solid #dddff1;
            width: 35%;
            // margin-right: 3%;
            border-radius: 0.5rem;
            padding: 8px 12px;

            .button {
                font-size: 0.9rem;
                font-weight: 500;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
}
