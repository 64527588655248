#button-style {
  margin-top: 20px;
  button {
    border-radius: 20px;
    width: 60%;
    padding: 10px;
    background-color: #00b9b7;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.reward-input-field-container {
    padding: 0px;
    margin: 5px;
    margin-left: 0px;
    margin-right: 0px;

  input {
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: none;
    background-color: #eeeff8;
    outline: none;
  }

  input::placeholder {
    font-size: 12px;
  }
}

.bottom-sheet {
  --rsbs-bg: rgba(255, 255, 255, 100);
}

:root {
  --rsbs-handle-bg: hsla(0, 0%, 100%, 0);
  --rsbs-bg: rgba(255, 255, 255, 0);
}
