@use "variables" as variable;

.category-banners-carousel {
  .slick-slider {
    .slick-slide {
      .banner-container {
        height: 100%;
        width: 100%;
        display: initial !important;
        img {
          width: 100%;
          height: 160px;
          object-fit: cover;
        }
      }
    }
    .slick-dots {
      bottom: 8px;
      li {
        margin: 0;
        width: 10px;
        height: 10px;
        button {
          padding: 0;
          width: 10px;
          height: 10px;
          &::before {
            color: #fff;
            opacity: 0.5;
            width: 10px;
            height: 10px;
            font-size: 5px;
          }
        }
        &.slick-active {
          button {
            &::before {
              color: #fff;
              opacity: 1;
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}
