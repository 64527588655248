@use "variables" as variable;
.rangeslider {
  opacity: none !important;
}

.referral-header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: overlay;

  .referral-header-container {
    background-color: #5427a6;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .referral-header-body {
    padding-bottom: 0;
    padding-right: 0;
    margin-bottom: 0%;
    width: 100%;
    color: #ffffff;
    font-size: 4.5vw;
    margin: 0;
    padding: 0;
  }
  .referral-header-title-2 {
    font-family: Roboto;
    font-size: 5.2vw;
    font-weight: 700;
    line-height: 10vw;
    letter-spacing: 0em;
    text-align: left;
    display: block;
  }
  .referral-header-back-button {
    background-color: #5427a6;
    border: none;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    align-items: "flex-start";
  }
  .overlapping-bar-upper {
    position: relative;
    padding: 5%;
    padding-bottom: 0;
    width: 100%;
    height: 1.5rem;
    background-color: #ffffff;
    /* z-index: 100; */
    margin: auto;
    margin-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.overlapping-bar-lower {
  background-color: #ffffff;
  height: 17vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 32px rgb(0 0 0 / 12%);
  position: relative;
  z-index: -100;
  flex-shrink: 0;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
}
.invite-link {
  background-color: #eee9f6;
  border-radius: 40px;
  width: -webkit-fill-available;
  height: 12vw;
  .invite-link-text {
    font-family: Roboto;
    font-size: 4.6vw;
    font-weight: 400;
    line-height: 8vw;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding: 2vw;
    padding-left: 0;
    margin: 0;
  }
}
.invite-friends-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 5vw;
  line-height: 6vw;
  /* identical to box height, or 133% */
  margin-bottom: 0%;
  padding-bottom: 0%;
  margin-top: 5%;
  color: #000000;
  padding-bottom: 2.2vw;
  padding-left: 1.8vw;
  opacity: 0.87;
}
.invite-friends-social {
  padding: 3.2vw;
  border-radius: 3vw;

}
.share-brand-name {
  font-family: Roboto;
  font-size: 5vw;
  line-height: 3.179650238473768vh;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
}

.invite-a-friend-tooltip {
  font-family: Roboto;
  font-size: 4vw;
  font-weight: 400;
  line-height: 6vw;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  background-color: #ff6a98;
  padding: 0.3rem;
  width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.invite-a-friend-tooltip-top {
}

.arrow_box_left {
  position: relative;
  margin: 0;
  padding: 0;
}
.arrow_box_left:before {
  bottom: 100%;
  left: 0%;
  content: "";
  position: absolute;
  border-bottom: 12px solid #ff6a98;
  border-right: 12px solid transparent;
  margin: 0;
  padding: 0;
}

.arrow_box_right {
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  left: 0;
}
.arrow_box_right:before {
  bottom: 100%;
  left: 0%;
  content: "";
  position: absolute;
  border-bottom: 9px solid #ff6a98;
  border-right: 8.9px solid transparent;
  margin: 0;
  padding: 0;
  transform: rotate(-90deg);
}

.invite-a-friend-tooltip-right {
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  background-color: #ff6a98;
  padding: 0.3rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  position: fixed;
}

.gift-box {
  position: absolute;
  top: 2.5vw;
  right: 4.5vw;
}

.referral-banner {
  height: 36vw;
  width: 92%;
  position: relative;
  align-content: center;
  justify-content: center;
}
// .bubbly{
//   padding-right: 7vw !important;
// }

.brand-button{
  padding: 1.5vw;
  padding-bottom: 0; 
}