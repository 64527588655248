@use "variables" as variable;

#home-page {
    &.sticky-search-bar {
        #home-search-bar {
            #search-bar-container {
                .user-icon-container {
                    display: flex !important;
                }
                .search-bar-input {
                    display: none !important;
                }
                .scroll-down-search-bar {
                    display: block !important;
                }
            }
        }
    }
    background-color: #e1e3f3;
    #header {
        position: absolute;
        height: variable.$header-height !important;
    }
    .search-bar-container {
        background-color: #ffffff;
    }
    .header-border-bottom {
        height: variable.$header-height + 0.8rem;
        // animation: smoothScrollDown 1.3s ease;
        // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.12);
        &.searchBar-focused {
            box-shadow: none;
        }
        .search-bar-home {
            padding-top: 4px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    #home-search-bar {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 50;
        padding: 8px 16px;
        #search-bar-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user-icon-container {
                border-radius: 0.4rem;
                border: none;
                border-right: 0px !important;
                box-shadow: none;
                justify-content: center;
                align-items: center;
                padding: 6px 0;
                padding-left: 20px;
                margin-bottom: auto;
                display: none;
                .user-icon {
                    height: 28px;
                    width: 28px;
                }
            }
        }
    }

    .button-showcase-section {
        padding-top: 20px;
        // padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #ffffff;

        .showcase-button {
            border-radius: 12px;
            height: 50px;
            width: 45vw;
            background-color: #f1f0f5;
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
        }

        .button-gif {
            padding-left: 5px;
        }

        .button-text {
            color: #000000;
            font-weight: 700;
            font-size: 13px;
            line-height: 14px;
            padding: 5px;
            // display: flex;
            // justify-content: center;
        }
    }

    #scroll-to-top-button-container {
        position: fixed;
        bottom: 12vh;
        right: 4vw;
        background-color: #ffffff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        border: 1px solid #eeeff8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

        .icon {
            color: #7a7d99;
            font-size: 1.25rem;
            font-weight: 700;
        }
    }
    .products-section {
        // border-top-left-radius: 24px;
        // border-top-right-radius: 24px;
        background-color: #ffffff;
        position: relative;
        padding-top: 12px;
        .category-container {
            // padding-top: 24px;
            padding-bottom: 8px;
            // border-top: 8px solid #e1e3f3;
            > .container {
                padding: 8px 16px !important;
            }
            #product-header {
                margin-bottom: 8px;
            }
        }
        .scroll-indicator {
            position: absolute;
            background: rgba(217, 217, 217, 0.7);
            border-radius: 30px;
            width: 2.375rem;
            height: 0.25rem;
            top: 4px;
            display: flex;
            margin: auto;
            left: 50%;
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .gradient-category {
            // background: linear-gradient(115.85deg, #eadaf7 5.39%, #f8dce9 92.16%);
            #product-header,
            #product-quick-preview-container {
                background-color: transparent;
            }
        }
    }
    .loader-container {
        &.home-screen-loader {
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .spinner {
                > div {
                    width: 5vw;
                    height: 5vw;
                    margin-left: 8px;
                    background-color: #1ca672;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .skeleton-container{
        height: 100vh;
        background-color: #ffffff;
        padding: 0;
        padding-top: 15px;
        overflow: hidden;
    }
}

.exploreByCategories {
    padding-bottom: 4px;
    .category {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        img {
            display: flex;
            margin: auto;
            object-fit: contain;
        }
    }
}

.memechat-category-icon{
    background: #ffffff;
    padding-bottom: 4px;
    
    .memechat-icon-image{
        display: flex;
        justify-content: center;
        padding-left: 1rem;
        padding-right: 1rem;

        img{
            border-radius: 16px;    
        }
    }

    .memechat-icon-text{
        font-weight: 600;
        font-size: 0.875rem;
        color: rgb(0, 0, 0);
        text-align: center;
        margin-top: 10px;
    }

}

@keyframes smoothScrollDown {
    0% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes smoothScrollUp {
    0% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0px);
    }
}
