@use 'variables'as variable;

#request-more-products-page {

    background-color: #FFFFFF;
    height: 100vh;

    .body {
        margin-top: 4.5rem !important;
        width: 90vw;
        margin: 0vw 5vw;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .para {
            font-weight: 400;
            font-size: 1rem;
        }

        .product-request-textarea {
            border-radius: 0.5rem;
            background-color: #EEEFF8;
            height: 7rem;
            border: none;
            resize: none;

            padding: 0.5rem !important;

            &::-moz-focus-inner {
                border: none !important;
            }
        }

        .submit-button {
            background-color: #141736;
            color: #FFFFFF !important;
            width: 90vw;
            font-size: 4vw;
            font-weight: 500;
            padding: 1rem 0.7rem;
            border-radius: 0.5rem;
        }

        .disabled {
            background-color: #7A7D99;
        }
    }

}