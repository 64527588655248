@use 'variables'as variable;


#bobble-coin {   
    background-color: #EEEFF8;
    border-radius: 1.9rem;
    height: 1.5rem;
    width: 4rem;

    .count-text {
        font-size: 0.9rem;            
        text-align: center;
    }
}