@use "variables" as variable;

#offer-header {
  background-color: #ffffff;

  .heading {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .sub-heading {
    font-size: 0.9rem;
    color: #7a7d99;
    font-weight: 400;
  }

  .circle-right-arrow-icon {
    height: 18px;
    width: 18px;
  }
}
