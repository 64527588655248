@use "variables" as variable;

#sort-and-filter {
    * {
        box-sizing: border-box;
    }

    .tabs {
        // height: 100vh;
        /* Style the tab */
        .tab {
            float: left;
            // border: 1px solid #ccc;
            background-color: #eeeff8;
            width: 35%;
            height: 100vh;
        }

        /* Style the buttons that are used to open the tab content */
        .tab button {
            display: block;
            background-color: inherit;
            color: #7a7d99;
            font-size: 0.9rem;
            padding: 0.8rem 0.8rem;
            width: 100%;
            border: none;
            outline: none;
            text-align: left;
            cursor: pointer;
            transition: 0.3s;
        }

        /* Create an active/current "tab button" class */
        .tab button.active {
            background-color: #ffffff;
            border-left: 5px solid #573996;
            color: #141736;
        }

        /* Style the tab content */
        .tabcontent {
            float: left;
            // margin-top: 2vw;
            padding: 0.8rem 0.8rem;
            border: 1px solid #ccc;
            width: 65%;
            border-left: none;
            background-color: #ffffff;
            height: 100vh;
            overflow-y: scroll;

            .input {
                margin: 0.5rem 0rem;
                font-size: 0.85rem;
                color: #141736;

                .form-check-input[type="radio"] {
                    width: 1.15rem;
                    border: 2px solid #7a7d99;
                    height: 1.15rem;
                }

                .form-check-input[type="radio"]:checked {
                    box-shadow: none !important;
                    background: none;
                    border: 2px solid #573996 !important;
                }

                input[type="radio"]:before {
                    content: "";
                    display: block;
                    width: 60%;
                    height: 60%;
                    margin: 20% auto;
                    border-radius: 50%;
                }

                .form-check-input[type="radio"]:checked:before {
                    background-color: #573996;
                }

                .form-check-input[type="checkbox"] {
                    width: 1.15rem;
                    border: 2px solid #7a7d99;
                    box-shadow: inset 0 0 0 0px #7a7d99 !important;
                    height: 1.15rem;
                }

                .form-check-input[type="checkbox"]:checked {
                    background-color: #573996;
                    border: 1px solid #573996;
                    color: #ffffff;
                    transition: 0.3s;
                }

                .form-check-label {
                    vertical-align: middle;
                    margin-left: 0.25rem;
                }

                input:checked {
                    border: 1px solid #573996 !important;
                }

                &:checked + label:before {
                    background: yellow;
                }
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        height: variable.$footer-height;
        width: 100vw;
        background: #ffffff;
        border-top: 1px solid #eeeff8;

        .container {
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .apply-filers-button {
                // margin-right: 3vw;
                color: #ffffff !important;
                font-size: 4vw;
                font-weight: 400;
                width: 25vw;
                padding: 0.6rem 0.7rem;
                border-radius: 0.5rem;
                background: #573996;
                text-align: center;
            }
        }
    }
}
