@use "variables" as variable;

#cart-page {
    height: fit-content;
    min-height: 100vh;
    background-color: #eeeff8;
    padding-bottom: 7rem;

    .section-break {
        background-color: #eeeff8;
    }

    .place-order-button {
        background: #573996;
        box-shadow: 0px 0px 18px rgb(116 108 108 / 10%);
        border-radius: 8px;
        color: #ffffff;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        display: inline-block;
        position: fixed;
        bottom: 8px;
    }

    .products {
        height: fit-content;
        margin-top: 28px;
        background-color: #ffffff;
        .heading {
            margin-bottom: 8px;
            padding-top: 20px;
            font-weight: 600;
        }
        .product {
            height: 9rem;
            width: 100vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeff8;
            border-top: 1px solid #eeeff8;

            .cart-image-container {
                width: auto;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: auto;

                .product-image {
                    height: 80px;
                    width: 100%;
                    object-fit: cover;
                }

                .product-out-of-stock-container {
                    background-color: #ffffff;
                    height: fit-content !important;

                    .out-of-stock-text {
                        color: #de3c63;
                        margin-bottom: 0px !important;
                        font-size: 0.85rem;
                        font-weight: 500;
                    }
                }
            }

            .product-body {
                width: 70%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .product-name {
                    color: #141736;
                    width: 95%;
                    font-size: 0.85rem;
                    overflow: hidden;
                    overflow-wrap: break-word;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* number of lines to show */
                    -webkit-box-orient: vertical;
                    font-weight: 500;
                }

                .price {
                    color: #141736;
                    font-size: 0.9rem;
                    font-weight: 800;
                    white-space: nowrap;
                }

                .product-price {
                    font-size: 0.9rem;
                    color: #7a7d99;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                }

                .percentage-off {
                    // margin-top: 0.1rem;
                    color: #2ca378;
                    // font-size: 0.9rem;
                    font-size: 3.5vw;
                    font-weight: 500;
                    word-spacing: 0.5vw;
                    // padding: 0 3%;

                    span {
                        color: #7a7d99;
                        font-weight: 400;
                    }
                }

                .increment-button {
                    margin-top: 0.5rem;
                    height: 33px;
                    width: fit-content;
                    margin-right: 16px;
                    background-color: #573996;
                    border: 1.2px solid #573996;
                    border-radius: 8px;

                    .plus-icon,
                    .minus-icon {
                        width: 12px;
                    }

                    .loader-container {
                        height: 2rem;
                        width: 8vw;
                        border: none;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: transparent;

                        .spinner-border {
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            vertical-align: -0.125em;
                            border: 0.15em solid currentColor;
                            border-right-color: transparent;
                            border-radius: 50%;
                            -webkit-animation: 0.75s linear infinite spinner-border;
                            animation: 0.75s linear infinite spinner-border;
                            color: #ffffff !important;
                        }
                    }

                    .minus,
                    .plus {
                        width: 6vw;
                        height: 2rem;
                        background: transparent;
                        font-size: 0.9rem;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        color: #ffffff;
                        cursor: pointer;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .minus {
                        border: none;
                        padding-left: 8px;
                    }

                    .plus {
                        border: none;
                        padding-right: 8px;
                    }

                    input {
                        height: 2rem;
                        width: 8vw;
                        text-align: center;
                        color: #ffffff;
                        background: transparent;
                        font-size: 0.9rem;
                        display: inline-block;
                        vertical-align: middle;
                        border: none;
                        outline: none;
                    }
                }
            }
        }
    }

    .cart-details {
        margin-top: 10px;
        .cart-details-container {
            background-color: #ffffff;
            padding-top: 4px;
            .details {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 8px 16px;

                .key {
                    color: #7a7d99;
                    font-size: 0.85rem;
                }

                .value {
                    color: #141736;
                    font-size: 0.85rem;
                    font-weight: 500;
                }

                .green-color {
                    color: #2ca378 !important;
                }
            }

            .total {
                font-weight: 700;
                margin: 2vw 0vw !important;
                height: 2.5rem;
                border-top: 1px solid #eeeff8;
                border-bottom: 1px solid #eeeff8;
                padding: 0 16px;
                .value {
                    font-weight: 700;
                }
            }
        }
    }

    .footer {
        height: 6.7rem !important;
        background: #ffffff;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px 3px 20px rgb(0 0 0 / 20%);

        .fa-angle-right {
            font-size: 3.5vw;
            color: #7a7d99;
        }

        .margin-top {
            margin-top: 0.75rem;
        }

        .cta-container {
            // display: flex;
            height: 2rem;

            .location {
                font-weight: 500;
            }

            .text {
                color: #7a7d99;
            }
        }
    }

    .user-location-component {
        display: flex;
        position: relative;
        padding-bottom: 8px;
        .location-black-icon,
        .right-arrow-icon {
            height: 20px;
        }
        .location {
            font-size: 0.9rem;
            font-weight: 400;
            color: #7a7d99;
            b {
                color: #141736;
                font-weight: 800;
            }
        }
    }
    .change-address {
        color: #5427a6;
        font-size: 0.9rem;
        font-weight: 500;
    }
}

.error-container {
    margin-top: calc(variable.$footer-height - 10%);

    // background-color: #EEEFF8;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .continue-shopping-button {
        width: 35%;
        background-color: #5427a6;
        color: #ffffff !important;
        font-size: 4vw;
        font-weight: 500;
        padding: 0.6rem 0.5rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
