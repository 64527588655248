#user-profile {
  .details-section {
    background-color: #f3edff;
    // height: 130px;
    .name-box {
      background-color: rgba(134, 113, 179, 0.1);
      border: 0;
      padding: 0.2rem 0.8rem;
      padding-right: 0px;
      border-radius: 0.5rem;
      margin-bottom: 6px;
      margin-left: 1rem;
      width: 15.625rem;
      &.disabled {
        background-color: transparent;
        padding-left: 0;
        .name-input-box {
          background-color: transparent;
          color: #573996 !important;
          font-weight: 500;
          padding-left: 0;
        }
      }
      &.emptyField {
        background-color: rgba(134, 113, 179, 0.1);
        padding: 0.2rem 0.8rem;
        padding-right: 0;
        .name-input-box {
          color: rgba(87, 57, 150, 0.4) !important;
          background-color: rgb(232, 225, 247);
          font-weight: 700;
          padding-left: auto;
        }
      }
      .edit-name-button {
        width: 40px;
        height: 40px;
        padding: 0px 8px;
      }
    }
  }

  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: row;

    // img{
    //     width: 60px;
    //     height: 60px;
    // }

    .small-heading {
      padding-left: 1rem;
      color: #573996;
      font-weight: 700;
      font-size: 20px;
    }

    .name-heading {
      padding-left: 1rem;
      color: #573996;
      font-weight: 700;
      font-size: 24px;
      margin-top: -8px;
    }

    .number-heading {
      padding-left: 1rem;
      // margin-top: 0.5rem;
      color: #573996;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .redirect-section {
    margin-top: 0.5rem;
    // position: relative;
    // border-top-left-radius : 24px;
    // border-top-right-radius : 24px;
    background-color: #fff;

    hr {
      width: 90%;
      margin: auto;
      margin-top: 1.2rem;
      color: #ececec;
      height: 2px;
    }

    .link-container {
      padding-top: 1rem;
      padding-left: 1.5rem;
      display: flex;
      flex-direction: row;

      .image-container {
        background-color: #f3edff;
        border-radius: 0.4rem;
        height: 30px;
        width: 30px;
        border: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .text-container {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        padding-left: 0.8rem;
        align-self: center;
      }

      .subtext-container {
        padding-top: 0.3rem;
        font-size: 12px;
        line-height: 14px;
        color: #8f8d93;
        font-weight: 400;
        letter-spacing: 0em;
        padding-left: 0.8rem;
        align-self: center;
      }

      .arrow-container {
        position: absolute;
        right: 1rem;
        // padding-left: 5rem;
        height: 20px;
        width: 20px;
        border: none;
        box-shadow: none;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-end;
        // align-items: center;
        // padding-top: 1rem;
      }
    }
  }

  .name-input-box {
    color: rgba(87, 57, 150, 0.4) !important;
    background-color: rgb(232, 225, 247);
    font-size: 1.375rem;
    width: 100%;
    font-weight: 700;
    // font-size: 22px;
    border: 0;
  }

  .name-input-box::-moz-placeholder {
    color: rgba(87, 57, 150, 0.4) !important;
    font-size: 1.375rem;
  }

  .name-input-box::-webkit-input-placeholder {
    color: rgba(87, 57, 150, 0.4) !important;
    font-size: 1.375rem;
  }

  .offer-banners-carousel {
    .slick-slider {
      .slick-slide {
        .banner-container {
          height: 100%;
          width: 100%;
          display: initial !important;
          // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          img {
            width: 100%;
            border-radius: 16px;
          }
        }
      }
      .slick-dots {
        bottom: 4vw;
        li {
          margin: 0;
          width: 10px;
          height: 10px;
          button {
            padding: 0;
            width: 10px;
            height: 10px;
            &::before {
              color: #d9d9d9;
              opacity: 0.5;
              width: 10px;
              height: 10px;
              font-size: 5px;
            }
          }
          &.slick-active {
            button {
              &::before {
                color: #000;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.button-wrapper {
  // display: flex;
  // justify-content: center;
  display: flex;
  align-items: center;
  width: 80%;
  .create-account-button {
    width: 100%;
    height: 80%;
    background-color: #5427a6;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 0.6rem 0.5rem;
    border-radius: 0.5rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#user-profile-login {
  .container {
    position: absolute;
    top: 35%;
    width: 100vw;
  }
  .outer-container {
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    // padding-top: calc(variable.$footer-height - 10%);

    .image {
      width: 28vw;
      height: 28vw;
    }

    .text {
      font-weight: 400;
      font-size: 1rem;
      text-align: center;
      color: #7a7d99;
      margin-top: 1rem;
      max-width: 80%;
    }
  }
  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .create-account-button {
    width: 100%;
    background-color: #5427a6;
    color: #ffffff !important;
    font-size: 4vw;
    font-weight: 500;
    padding: 0.6rem 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .existing-user-text {
    font-size: 12px;
    font-weight: 500;
    color: #7a7d99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.7rem;
    align-items: center;
    .button-container {
      margin-left: 5px;
      background-color: #f3edff;
      border-radius: 0.4rem;
      height: 24px;
      width: 46px;
      border: none;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
