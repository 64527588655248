@use "variables" as variable;

#add-to-cart-button {
  display: block;
  height: 30px;
  width: 100%;
  padding-left: 16px;
  .minus,
  .plus {
    // width: 10vw;
    // height: 2rem;
    border: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 5vw;
    cursor: pointer;
  }

  .plus-icon,
  .minus-icon {
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff !important;
  }

  .minus {
    border: none;
    padding-left: 8px;
  }

  .plus {
    border: none;
    padding-right: 8px;
  }

  .disabled {
    opacity: 0.5;
  }

  input {
    // height: 2rem;
    width: 70%;
    text-align: center;
    font-size: 0.9rem;
    border: none;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    color: #ffffff !important;
    &:focus,
    :hover {
      border: none;
      outline: none;
    }
  }

  .add-button {
    color: #1ca672 !important;
    font-size: 0.9rem;
    font-weight: 600;
    height: 30px;
    width: 100%;
    padding: 0.4rem 1rem;
    border-radius: 8px;
    background: #e2ffe5;
    border: 1.2px solid #1ca672;
    display: inline-block;
  }

  .increment-button {
    background-color: #1ca672;
    height: 30px;
    width: 100%;
    border-radius: 8px;
    border: 1.2px solid #1ca672;
    color: #1ca672 !important;
    button {
      background-color: transparent;
    }

    input {
      background-color: transparent;
    }
  }

  .loader-container {
    // height: 2rem;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    .spinner {
      > div {
        background-color: #e2ffe5;
      }
    }

    .spinner-border {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: -0.125em;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: 0.75s linear infinite spinner-border;
      animation: 0.75s linear infinite spinner-border;
      color: #ffffff !important;
    }
  }

  .add-to-cart-loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2ffe5;
    border: 1.2px solid #1ca672;
    border-radius: 8px;
    height: 100%;
    .spinner {
      > div {
        background-color: #1ca672;
      }
    }

    .spinner-border {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: -0.125em;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: 0.75s linear infinite spinner-border;
      animation: 0.75s linear infinite spinner-border;
      color: #1ca672 !important;
    }
  }

  .add-button-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .button-icon {
      color: #7a7d99;
      width: 1.1rem;
      height: 1.1rem;
    }

    .text {
      padding-left: 2.5%;
    }
  }
}
