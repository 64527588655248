@use "variables" as variable;

#separator {
    display: flex;
    align-items: center;
    padding-left: 20%;
    padding-right: 20%;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .linebr {
        height: 1px;
        flex: 1;
        background-color: lightgray;
        margin-bottom: 15px;
    }

    p {
        padding: 0 0.6rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: center;
    }
}
