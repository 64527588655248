@use 'variables' as variable;

#wishlist-page {

    background-color: #EEEFF8;
    min-height: 100vh;
    height: max-content;

    .products-container {
        display: flex;
        flex-flow: row wrap;
        // justify-content: space-around;
        border: 0px !important;
        // background-color: #ffffff;
    
        text-align: center;
    
        #product-quick-preview-card {
          border-radius: 0;
          box-shadow: none;
          &:nth-child(odd) {
            padding: 0 1vw;
            // margin: 1px 1px 0vw 0vw;
            // margin: 0.75vw;
            border-right: 1px solid #e9e9e9 !important;
            border-bottom: 1px solid #e9e9e9 !important;
          }
    
          &:nth-child(even) {
            padding: 0 1vw;
            // margin: 0.75vw;
            // margin: 1px 0vw 0vw 0vw;
            border-bottom: 1px solid #e9e9e9 !important;
          }
    
          width: 49.5vw;
          // height: 20.5rem;
          padding-top: 12px !important;
          padding-bottom: 12px !important;
          min-width: 0;
          margin-right: 0 !important;
          // padding: 0 1vw;
          // border-radius: 0.8rem;
          .discount-tag {
            top: 8px;
            left: 8px;
            width: 2.1rem;
            .sale-tag {
              border-top-left-radius: 0.6rem !important;
              border-top-right-radius: 0.6rem !important;
              width: 2.1rem;
            }
            .percentage-off {
              font-size: 0.65rem;
            }
          }
    
          .card-image-container {
            .card-image {
              object-fit: cover;
              width: 120px;
              display: block;
              margin: auto;
            }
          }
    
          .product-name {
            font-size: 0.875rem;
          }
    
          .price {
            padding: 0 3%;
            margin-bottom: 0.25rem;
    
            .original-price {
              font-size: 1rem;
            }
    
            .discounted-price {
              font-size: 1rem;
            }
          }
    
          .share-button-loader {
            width: 36px;
            height: 36px;
          }
    
          .share-button {
            height: 36px;
            width: 36px;
          }
          #add-to-cart-button {
            height: 36px;
            > div {
              height: 100%;
              width: 100%;
              font-size: 1rem;
              .add-button {
                height: 100%;
                width: 100%;
                font-size: 1rem;
              }
              input {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }