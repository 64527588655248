@use "variables" as variable;

.header-border-bottom {
  // height: 1px;

  // flex: 1;
  // border-bottom: 1px solid #dddff1;
  margin: 0 0 0rem 0;
  background-color: #dddff1;
  // filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.16));
}

#header {
  background-color: #ffffff;
  position: fixed;
  min-width: 100vw;
  height: variable.$header-height-rest;
  top: 0px;
  z-index: 20;

  // -webkit-transition: all 0.5s ease;
  // -moz-transition: position 10s;
  // -ms-transition: position 10s;
  // -o-transition: position 10s;
  // transition: all 0.5s ease;

  .header-body {
    height: inherit;
    // border-bottom: 1px solid #e5e5e5;
  }

  .left-arrow-icon {
    color: #7a7d99;
  }

  .back-arrow-icon {
    height: 20px;
    width: 20px;
  }

  // when search bar is focused
  &.searchBar-focused {
    .search-bar-container {
      background-color: #ffffff !important;
      border: none !important;
      width: 100% !important;
      justify-content: flex-start !important;
      align-items: center;
      padding-left: 2%;
      height: 2.66rem;
      display: flex;
      // margin: 0 8px;
    }
    .user-icon-container {
      display: none;
    }
    .header-body {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .header-right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-right: 2vw;
    // margin-left: 2vw;

    .search-bar-icon-container {
      background: #f1f0f5;
      border-radius: 8px;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-counter-container {
      // margin: 0px 6%;
      margin-left: 12px;
      background: #f1f0f5;
      border-radius: 8px;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      // width: 40px;
      .fa-angle-right {
        font-size: 3.5vw;
        color: #7a7d99;
      }

      .cart-icon {
        height: 20px;
        width: 20px;
      }

      .wishlist-icon {
        height: 20px;
        width: 20px;
      }

      .counter {
        font-size: 0.48rem;
        color: #fff;
        border-radius: 50%;
        border: 0;
        height: 0.7rem;
        position: absolute;
        width: 0.7rem;
        text-align: center;
        padding-top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .counter-wishlist {
        top: -0.6rem;
        left: 70%;
      }
    }

    .powered-by-container {
      width: 40vw;
      margin-right: 2vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 360px) {
        width: 55vw;
      }
    }

    .powered-by {
      color: #aeb0c2;
      font-size: 0.7rem;
    }

    .company-logo {
      width: 1rem;
      height: 1rem;
      margin: 0vw 0.25rem;
    }

    .company-name {
      color: #141736;
      font-size: 0.8rem;
    }
  }

  .user-icon-container {
    // margin-left: 4vw;
    border-radius: 0.4rem;
    border: none;
    border-right: 0px !important;
    // background-color: #f1f0f5;
    box-shadow: none;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    padding-right: 14px;
    margin-bottom: auto;
    .icon {
      color: #454545;
    }

    .search-icon {
      height: 18px;
      width: 18px;
    }

    .user-icon {
      height: 28px;
      width: 28px;
    }

    .search-bar-icon {
      color: #7a7d99;
      width: 0.5rem;
      height: 0.8rem;
    }
  }

  #user-location-component {
    color: #454545;
    font-weight: 600;
    position: absolute;
    margin-bottom: auto;
    // background-color: #f1f0f5;
    border-radius: 0.4rem;
    padding: 4px 12px 4px;
    .right-arrow-icon {
      color: #7a7d99;
    }

    a {
      color: #454545;
      height: 100%;
      display: block;
    }

    a:visited {
      color: #454545;
    }

    a:hover {
      color: #454545;
    }

    .location-icon {
      height: 25px;
      margin-right: 2px;
    }

    .address-type {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      font-size: 1rem;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    .location {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      font-size: 11px;
      // max-width: 32vw;
      font-weight: 400;
      line-height: 13px;
      color: #8f8d93;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      width: 50vw;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
    }
  }

  .search-bar-container {
    display: flex;
    justify-content: center;
    $width: 80vw;
    width: $width;
    padding-left: 2%;
    height: calc(variable.$header-height * 0.7);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .icon {
      color: #7a7d99;
      width: 0.5rem;
      height: 0.8rem;
    }
    .search-icon {
      // margin-top: -5px;
      height: 16px;
      padding-left: 8px;
    }

    .back-button-icon {
      height: 14px;
      padding-left: 8px;
      padding-right: 4px;
    }

    .user-icon-container {
      padding: 9px 0;
      width: 36px;
    }

    .search-bar {
      // padding: 0.2rem 0rem;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      // border: 1.5px solid #c9c9c9;
      // border-left: 0px !important;
      border: none !important;
      width: 100%;
      background-color: transparent;
      padding: 8px 0;
      padding-left: 8px;
    }

    .search-bar:focus {
      outline: none;
    }
  }

  .clear-filters-button {
    width: 24vw;
    margin-right: 2vw;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 76%;
    top: -85%;

    .text {
      color: #141736;
      opacity: 0.6;
      font-size: 0.85rem;
    }
  }

  .saved-addresses-button {
    width: 35vw;
    margin-right: 2vw;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 62%;
    top: -85%;
    border: 1px solid #dddff1;
    border-radius: 8px;

    .text {
      color: #141736;
      // opacity: 0.6;
      font-size: 75%;
      padding: 5%;
    }
  }

  .search-bar-home {
    // margin: 2vw 0;
    // margin-right: 8px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .search-bar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 2%;
      // $width: 100%;
      // width: $width;
      height: calc(variable.$header-height * 0.7);
      border: 1.5px solid #c9c9c9;
      background-color: #f1f0f5;
    }

    .item-counter-container {
      // margin: 0px 6%;
      margin-right: 2%;

      .cart-icon {
        height: 20px;
        width: 20px;
      }

      .counter {
        font-size: 0.45rem;
        color: #fff;
        padding: 0.1rem;
        border-radius: 50%;
        top: -0.5rem;
        background-color: #00b9b7;
        border: 0;
        position: relative;
        padding: 0.2rem;
        height: 0.9rem;
        top: 0.9rem;
        right: 2.75%;
        position: absolute;
        width: 0.9rem;
        text-align: center;
        padding: 0.1rem 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .counter-wishlist {
        top: -0.6rem;
        left: 70%;
      }
    }

    .powered-by-container {
      width: 40vw;
      margin-right: 2vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 360px) {
        width: 55vw;
      }
    }

    .powered-by {
      color: #aeb0c2;
      font-size: 0.7rem;
    }

    .company-logo {
      width: 1rem;
      height: 1rem;
      margin: 0vw 0.25rem;
    }

    .company-name {
      color: #141736;
      font-size: 0.8rem;
    }
  }
}
