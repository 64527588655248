@use "variables"as variable;

#order-items-page {

    background-color: #EEEFF8;
    height: 100vh;
    
    #ordered-product-preview-card {
        background-color: #ffffff;
        height: 7rem;
        width: 100vw;

        .product-image-container {
            width: 30vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .product-image {
                width: 80%;
                height: 90%;
                object-fit: contain;
            }
        }

        .body {
            max-width: 70vw;

            .product-name {
                color: #141736;
                display: inline-block;
                // vertical-align: middle;
                overflow: hidden;
                white-space: nowrap;
                font-size: 0.9rem;
                width: 95%;
                text-overflow: ellipsis;
            }

            .price {
                color: #141736;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

    }

}