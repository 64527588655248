@use "variables" as variable;

#carts-page {
    background-color: #eeeff8;
    height: 100vh;

    .vendor-cart-container {
        background-color: #ffffff;

        .vendor-cart {
            height: variable.$vendor-cart-height;

            .header {
                height: calc(variable.$vendor-cart-height * 0.2);
                padding: 0 1vw;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eeeff8;

                .company-logo {
                    margin: 0 2vw;
                    width: 5vw;
                }

                .company-name {
                    font-weight: 500;
                    color: #141736;
                    font-size: 0.95rem;
                }
            }

            .products {
                height: calc(variable.$vendor-cart-height * 0.6);
                width: 100vw;
                overflow-x: scroll;
                display: flex;
                align-items: center;
                // justify-content: space-around;

                .product {
                    flex-shrink: 0;
                    width: 25vw;
                    height: 90%;
                    margin: 2vw 2vw;

                    .product-image {
                        max-width: 100%;
                    }
                }
            }

            .footer {
                height: calc(variable.$vendor-cart-height * 0.2);
                display: flex;
                border-top: 1px solid #eeeff8;

                padding: 0 3vw;
                align-items: center;
                justify-content: space-between;

                .text {
                    color: #141736;
                    font-size: 0.85rem;
                }

                .icon {
                    color: #aeb0c2;
                    font-weight: 500;
                }
            }
        }
    }

    .section-break {
        background-color: #eeeff8;
    }

    .error-message-container {
        .continue-shopping-button {
            width: 35%;
            background-color: #5427a6;
            color: #ffffff !important;
            font-size: 4vw;
            font-weight: 500;
            padding: 0.6rem 0.5rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
